<template>
  <div>
    <b-card
      v-if="typeProductForm === 'Add' || typeProductForm === 'Edit'"
      border-variant="success"
      style="margin-bottom: 0.6rem;"
    >
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormFeeObserver"
      >
        <b-form
          ref="refFormFee"
          class="position-relative"
          @submit.prevent="handleSubmit(onSubmitFee)"
          @reset.prevent="resetForm"
        >
          <b-row>
            <!-- Field: Fee Type -->
            <b-col
              cols="12"
              md="2"
            >

              <b-form-group
                :label="$t('FormProduct.FeeType')"
                label-for="product-fee-type"
              >
                <validation-provider
                  #default="validationContext"
                  :name="$t('FormProduct.FeeType')"
                  rules="required"
                >
                  <v-select
                    v-model="formFeeData.fee_type"
                    :options="feeData.fee_type_options"
                    :reduce="val => val.value"
                    :clearable="false"
                    input-id="product-fee-type"
                    :state="getValidationState(validationContext)"
                  />
                  <small class="text-danger">{{ validationContext.errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Field: is_booking, is_renting -->
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group
                :label="$t('FormProduct.UseWith')"
                label-for="product-fee-use-with"
              >
                <span class="form-info-box">
                  <span
                    v-if="formFeeData.is_booking === true"
                    class="mr-50"
                    style="font-size: 14px;"
                  >
                    <b-badge
                      pill
                      variant="light-warning"
                      class="px-1"
                    >
                      {{ (formFeeData.is_booking === true)? $t('Booking') : '' }}
                    </b-badge>
                  </span>
                  <span
                    v-if="formFeeData.is_renting === true"
                    class="mr-50"
                    style="font-size: 14px;"
                  >
                    <b-badge
                      pill
                      variant="light-info"
                      class="px-1"
                    >
                      {{ (formFeeData.is_renting === true)? $t('Renting') : '' }}
                    </b-badge>
                  </span>
                </span>
              </b-form-group>
            </b-col>

            <!-- Field: Quality/Unit -->
            <b-col
              cols="12"
              md="2"
            >
              <b-form-group
                :label="$t('FormProduct.Quality')"
                label-for="product-fee-qty"
              >
                <validation-provider
                  #default="validationContext"
                  :name="$t('FormProduct.Quality')"
                  rules="required"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="product-fee-qty"
                      v-model="formFeeData.qty"
                      :state="getValidationState(validationContext)"
                      :disabled="busy"
                      @keypress="IsNumber"
                    />
                    <b-input-group-append is-text>
                      {{ (formFeeData.unit !== '')? $t(`FeeTypeUnit.${formFeeData.unit}`) : '' }}
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ validationContext.errors[0] }}</small>
                </validation-provider>

              </b-form-group>
            </b-col>

            <!-- Field: Value/Unit -->
            <b-col
              cols="12"
              md="2"
            >
              <b-form-group
                :label="$t('FormProduct.Value')"
                label-for="product-fee-value"
              >
                <validation-provider
                  #default="validationContext"
                  :name="$t('FormProduct.Value')"
                  rules="required"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="product-fee-value"
                      v-model="formFeeData.value"
                      :state="getValidationState(validationContext)"
                      :disabled="busy"
                      @keypress="IsNumber"
                    />
                    <b-input-group-append is-text>
                      {{ (formFeeData.value_unit !== '')? $t(`FormProduct.${formFeeData.value_unit}`) : '' }}
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ validationContext.errors[0] }}</small>
                </validation-provider>

              </b-form-group>
            </b-col>

            <!-- Field: Status -->
            <b-col
              cols="3"
            >
              <b-form-group
                :label="$t('Status')"
                label-for="product-fee-status"
              >
                <validation-provider
                  #default="validationContext"
                  :name="$t('Status')"
                  rules="required"
                >
                  <b-form-radio
                    v-model="formFeeData.status"
                    name="product-fee-status"
                    value="active"
                    class="custom-control-success cursor-pointer"
                    inline
                  >
                    <span class="cursor-pointer">{{ $t('Active') }}</span>
                  </b-form-radio>
                  <b-form-radio
                    v-model="formFeeData.status"
                    name="product-fee-status"
                    value="inactive"
                    class="custom-control-danger cursor-pointer"
                    inline
                  >
                    <span class="cursor-pointer">{{ $t('Inactive') }}</span>
                  </b-form-radio>
                  <small class="text-danger">{{ validationContext.errors[0] }}</small>
                </validation-provider>

              </b-form-group>
            </b-col>

          </b-row>
          <b-row>
            <!-- Field: Remark -->
            <b-col
              cols="12"
              md="7"
            >
              <b-form-group
                :label="$t('FormProduct.Remark')"
                label-for="product-fee-remark"
              >
                <b-form-input
                  id="product-fee-remark"
                  v-model="formFeeData.remark"
                  :disabled="busy"
                />
              </b-form-group>
            </b-col>

            <!-- Field: Sort -->
            <b-col
              cols="4"
            >
              <b-form-group
                :label="$t('Sort')"
                label-for="product-fee-sort"
              >
                <validation-provider
                  #default="validationContext"
                  :name="$t('Sort')"
                  rules="required"
                >
                  <b-row class="mt-1">
                    <b-col cols="3">
                      <b-form-radio
                        v-model="formFeeData.sort_type"
                        name="product-fee-sort"
                        value="input"
                        class="custom-control-info cursor-pointer"
                        inline
                      >
                        <span class="cursor-pointer">{{ $t('Input') }}</span>
                      </b-form-radio>
                    </b-col>
                    <b-col cols="3">
                      <b-form-input
                        id="product-fee-sort"
                        v-model="formFeeData.sort"
                        :state="getValidationState(validationContext)"
                        :disabled="!(formFeeData.sort_type === 'input')"
                        class="mt-n75"
                        @keypress="IsNumber"
                      />
                    </b-col>
                    <b-col
                      cols="3"
                    >
                      <b-form-radio
                        v-model="formFeeData.sort_type"
                        name="product-fee-sort"
                        value="first"
                        class="custom-control-info cursor-pointer"
                        inline
                      >
                        <span class="cursor-pointer">{{ $t('First') }}</span>
                      </b-form-radio>
                    </b-col>
                    <b-col
                      cols="3"
                    >
                      <b-form-radio
                        v-model="formFeeData.sort_type"
                        name="product-fee-sort"
                        value="last"
                        class="custom-control-info cursor-pointer"
                        inline
                      >
                        <span class="cursor-pointer">{{ $t('Last') }}</span>
                      </b-form-radio>
                    </b-col>
                  </b-row>
                  <small class="text-danger">{{ validationContext.errors[0] }}</small>
                </validation-provider>

              </b-form-group>
            </b-col>
          </b-row>

          <!-- Action: Button -->
          <b-row class="mt-1">
            <b-col
              cols="12"
            >
              <div
                class="float-right"
              >
                <b-button
                  variant="outline-secondary"
                  type="reset"
                  class="mr-1"
                  @click.prevent="resetFormFee"
                >
                  <feather-icon icon="RefreshCwIcon" />
                  {{ $t('Reset') }}
                </b-button>
                <b-button
                  ref="submit"
                  variant="primary"
                  :disabled="busy"
                  @click="onSubmitFee"
                >
                  <feather-icon icon="PlusIcon" />
                  {{ $t('Add') }}
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>

      <b-overlay
        :show="busyConfirm"
        no-wrap
        @shown="onShown"
        @hidden="onHidden"
      >
        <template #overlay>
          <div
            v-if="processingConfirm"
            class="text-center p-2 bg-primary text-light rounded"
          >
            <feather-icon
              icon="UploadCloudIcon"
              size="20"
            />
            <div class="mb-2">
              {{ `${$t('Processing')}...` }}
            </div>
            <b-progress
              min="1"
              max="10"
              :value="counterConfirm"
              variant="success"
              height="6px"
              class="mx-n1"
            />
          </div>
          <div
            v-else
            ref="dialog"
            tabindex="-1"
            role="dialog"
            aria-modal="false"
            aria-labelledby="form-confirm-label"
            class="text-center p-3"
          >
            <b-card-text
              class="font-weight-bolder"
            >
              {{ $t('Are you sure?') }}
            </b-card-text>
            <div class="d-flex">
              <b-button
                variant="outline-danger"
                class="mr-3"
                @click="onCancel"
              >
                {{ $t('Cancel') }}
              </b-button>
              <b-button
                ref="dialogOk"
                variant="outline-success"
                @click="onOK"
              >
                {{ $t('Ok') }}
              </b-button>
            </div>
          </div>
        </template>
      </b-overlay>
    </b-card>

    <b-card
      border-variant="success"
      no-body
      style="margin-top:1em;"
    >
      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <b-col
            cols="12"
            md="12"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ `${$t('Total')} ${feeData.table_fee.length} ${$t('Entries')}` }}</label>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refTableFee"
        :items="feeData.table_fee"
        responsive
        :fields="tableColumnFee"
        primary-key="id"
        show-empty
        hover
        empty-text="No matching records found"
        class="position-relative"
      >
        <!-- Column: Sort -->
        <template #cell(sort)="data">
          <div class="text-nowrap">
            <span class="align-text-top">{{ data.item.sort }}</span>
          </div>
        </template>

        <!-- Column: Fee Type -->
        <template #cell(fee_type)="data">
          <div class="text-nowrap">
            <span class="align-text-top">
              {{ data.item.fee_type.name }}
            </span>
          </div>
        </template>

        <!-- Column: Use With -->
        <template #cell(use_with)="data">
          <div class="text-nowrap">
            <span class="align-text-top">
              <span
                v-if="data.item.is_booking === true"
                class="mr-50"
                style="font-size: 14px;"
              >
                <b-badge
                  pill
                  variant="light-warning"
                  class="px-1"
                >
                  {{ (data.item.is_booking === true)? $t('Booking') : '' }}
                </b-badge>
              </span>
              <span
                v-if="data.item.is_renting === true"
                class="mr-50"
                style="font-size: 14px;"
              >
                <b-badge
                  pill
                  variant="light-info"
                  class="px-1"
                >
                  {{ (data.item.is_renting === true)? $t('Renting') : '' }}
                </b-badge>
              </span>
            </span>
          </div>
        </template>

        <!-- Column: Quality -->
        <template #cell(qty)="data">
          <div class="text-nowrap">
            <span class="align-text-top">
              {{ `${data.item.qty} ${$t(`FeeTypeUnit.${data.item.unit}`)}` }}
            </span>
          </div>
        </template>

        <!-- Column: Value -->
        <template #cell(value)="data">
          <div class="text-nowrap">
            <span class="align-text-top">
              {{ `${data.item.value} ${$t(`FormProduct.${data.item.value_unit}`)}` }}
            </span>
          </div>
        </template>

        <!-- Column: Remark -->
        <template #cell(remark)="data">
          <div class="text-nowrap">
            <span class="align-text-top">
              {{ data.item.remark }}
            </span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <!-- Status -->
          <div style="margin-top: 0.3rem;">
            <span style="font-size: 18px;">
              <b-badge
                pill
                :variant="`light-${isStatusVariant(data.item.status)}`"
                class="text-capitalize"
              >
                {{ $t(isStatusToText(data.item.status)) }}
              </b-badge>
            </span>
          </div>
        </template>

        <!-- Column: Actions -->
        <template
          v-if="typeProductForm === 'Add' || typeProductForm === 'Edit'"
          #cell(actions)="data"
        >
          <div class="text-nowrap">
            <a
              class="action-icon"
              @click.stop="removeFee(data.item)"
            >
              <feather-icon
                icon="TrashIcon"
                variant="light-success"
                size="16"
                class="cursor-pointer mr-1"
                @click="removeFee(data.item)"
              />
            </a>
          </div>
        </template>

      </b-table>
    </b-card>

  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormInput,
  BTable,
  BButton,
  BOverlay,
  BForm,
  BCardText,
  BBadge,
  BInputGroup,
  BInputGroupAppend,
  BFormRadio,
  BProgress,
} from 'bootstrap-vue'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import vSelect from 'vue-select'
import tableProduct from './tableProduct'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BTable,
    BButton,
    BOverlay,
    BForm,
    BCardText,
    BBadge,
    BInputGroup,
    BInputGroupAppend,
    BFormRadio,
    BProgress,

    ValidationProvider,
    ValidationObserver,
    FeatherIcon,
    vSelect,
  },
  props: {
    typeProductForm: {
      type: String,
      default: 'Add',
    },
    feeData: {
      type: Object,
      default: () => {},
    },
    busy: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formFeeData: {
        fee_type: '',
        is_booking: false,
        is_renting: false,
        qty: '',
        unit: '',
        value: '',
        value_unit: 'Baht',
        remark: '',
        sort: 1,
        sort_type: 'last',
        status: 'active',
      },
      tableColumnFee: [
        { key: 'sort', sortable: true, label: this.$i18n.t('Sort') },
        { key: 'fee_type', sortable: false, label: this.$i18n.t('FormProduct.FeeType') },
        { key: 'use_with', sortable: false, label: this.$i18n.t('FormProduct.UseWith') },
        { key: 'qty', sortable: false, label: `${this.$i18n.t('FormProduct.Quality')} - ${this.$i18n.t('FormProduct.Unit')}` },
        { key: 'value', sortable: false, label: `${this.$i18n.t('FormProduct.Value')} - ${this.$i18n.t('FormProduct.Unit')}` },
        { key: 'remark', sortable: false, label: this.$i18n.t('FormProduct.Remark') },
        { key: 'status', sortable: false, label: this.$i18n.t('FormProduct.Status') },
        // { key: 'actions', sortable: false, label: this.$i18n.t('Actions') },
      ],
      busyConfirm: false,
      processingConfirm: false,
      counterConfirm: 1,
      isAction: '',
    }
  },
  watch: {
    // eslint-disable-next-line func-names
    'formFeeData.fee_type': function (value) {
      const { is_booking, is_renting, unit } = value
      // eslint-disable-next-line camelcase
      if (is_booking !== undefined) {
        // eslint-disable-next-line camelcase
        if (is_booking === 1) {
          this.formFeeData.is_booking = true
        } else {
          this.formFeeData.is_booking = false
        }
      }
      // eslint-disable-next-line camelcase
      if (is_renting !== undefined) {
        // eslint-disable-next-line camelcase
        if (is_renting === 1) {
          this.formFeeData.is_renting = true
        } else {
          this.formFeeData.is_renting = false
        }
      }
      if (unit !== undefined) {
        this.formFeeData.unit = unit
      }
    },
    // eslint-disable-next-line func-names
    'formFeeData.sort_type': function (value) {
      if (value === 'first') {
        this.formFeeData.sort = 1
      } else if (value === 'last') {
        // sort last
        this.formFeeData.sort = this.feeData.table_fee.length + 1
      }
    },
  },
  mounted() {
    if (this.typeProductForm === 'Add' || this.typeProductForm === 'Edit') {
      this.tableColumnFee.push({ key: 'actions', sortable: false, label: this.$i18n.t('Actions') })
    }
  },
  methods: {
    onShown() {
      this.$refs.dialogOk.focus()
    },
    onHidden() {
      this.$refs.submit.focus()
    },
    clearFormFee() {
      this.formFeeData = {
        fee_type: '',
        is_booking: false,
        is_renting: false,
        qty: '',
        unit: '',
        value: '',
        value_unit: 'Baht',
        remark: '',
        sort: 1,
        sort_type: 'last',
        status: 'active',
      }
    },
    resetFormFee() {
      this.processingConfirm = false
      this.busyConfirm = true
      this.isAction = 'reset'
    },
    onSubmitFee() {
      this.$refs.refFormFeeObserver.validate().then(success => {
        if (success) {
          this.processingConfirm = false
          this.busyConfirm = true
          this.isAction = 'add'
        }
      })
    },
    onCancel() {
      this.busyConfirm = false
    },
    onOK() {
      this.processingConfirm = true
      if (this.isAction === 'add') {
        this.addFee()
      } else if (this.isAction === 'reset') {
        console.log('reset')
        this.clearFormFee()
        this.$refs.refFormFeeObserver.reset()
        this.busyConfirm = false
        this.processingConfirm = false
      }
    },
    IsNumber(event) {
      if (!/\d/.test(event.key)) return event.preventDefault()
      return null
    },
    addFee() {
      const lastSort = this.feeData.table_fee.length + 1
      if (this.formFeeData.sort_type === 'last') {
        this.formFeeData.sort = lastSort
        this.feeData.table_fee.push(this.formFeeData)
      } else if (this.formFeeData.sort_type === 'first' || this.formFeeData.sort_type === 'input') {
        let sort = 1
        if (this.formFeeData.sort_type === 'input') {
          let inputSort = parseInt(this.formFeeData.sort, 10)
          if (inputSort === 0) {
            inputSort = 1
          } else if (inputSort > lastSort) {
            inputSort = lastSort
          }
          sort = inputSort
        }
        let cntSort = 1
        if (this.feeData.table_fee.length === 0) {
          this.formFeeData.sort = 1
          this.feeData.table_fee.push(this.formFeeData)
        } else if (sort >= lastSort) {
          this.formFeeData.sort = lastSort
          this.feeData.table_fee.push(this.formFeeData)
        } else {
          const feeArray = []
          this.feeData.table_fee.forEach(fee => {
            if (cntSort === sort) {
              // new
              this.formFeeData.sort = sort
              feeArray.push(this.formFeeData)
              cntSort += 1
            }
            // old
            fee.sort = cntSort
            feeArray.push(fee)
            cntSort += 1
          })
          this.feeData.table_fee = feeArray
        }
      }
      this.clearFormFee()
      this.$refs.refFormFeeObserver.reset()
      this.busyConfirm = false
      this.processingConfirm = false
      // sort last
      this.formFeeData.sort = this.feeData.table_fee.length + 1
    },
    removeFee(item) {
      this.$swal({
        title: this.$i18n.t('Are you sure?'),
        text: this.$i18n.t("You won't be able to revert this"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$i18n.t('Yes, remove it'),
        cancelButtonText: this.$i18n.t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value === true) {
          // remove
          let indexSort = 1
          const sort = parseInt(item.sort, 10)
          const feeArray = []
          this.feeData.table_fee.forEach(fee => {
            if (parseInt(fee.sort, 10) !== sort) {
              fee.sort = indexSort
              feeArray.push(fee)
              indexSort += 1
            }
          })
          this.feeData.table_fee = feeArray
        }
      })
    },
  },
  setup() {
    const {
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
    } = formValidation()

    const {
      isStatusVariant,
      isStatusToText,
    } = tableProduct()

    return {
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,

      isStatusVariant,
      isStatusToText,
    }
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/_variables';
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.modal{
  .line-height-info{
    padding-bottom: 0.6rem;
    &.end{
      padding-bottom: 0;
    }
  }
  .modal-header,
  .modal-header[class*='bg-'] {
    background-color: #fff !important;
  }
  .modal-body {
    background-color: $body-bg !important;
  }
}
.form-info-box{
  display: flex;
  padding: 0.6rem 1rem;
  background-color: #f8f8f8;
  background-clip: padding-box;
  border: 1px solid #f0f0f0;
  border-radius: 0.357rem;
  min-height: 38px;
}

.dark-layout {
  .modal{
    .line-height-info{
      padding-bottom: 0.6rem;
      &.end{
        padding-bottom: 0;
      }
    }
    .modal-header,
    .modal-header[class*='bg-'] {
      background-color: $theme-dark-body-bg !important;
    }
    .modal-body {
      background-color: $theme-dark-table-active-bg !important;
    }
  }
  .form-info-box{
    display: flex;
    padding: 0.6rem 1rem;
    background-color: $theme-dark-input-bg;
    background-clip: padding-box;
    border: 1px solid $theme-dark-border-color;
    border-radius: 0.357rem;
  }
}
</style>
