<template>

  <b-overlay
    :show="showOverlay"
    rounded="sm"
  >
    <b-modal
      id="form-product"
      :visible="shallShowProductFormModal"
      :title="`${$t('Form')} ${$t('TitleMaster.Product')}`"
      footer-class="d-flex justify-content-between"
      size="xl"
      centered
      no-close-on-backdrop
      @change="(val) => $emit('update:shall-show-product-form-modal', val)"
    >
      <!-- Modal Header -->
      <template #modal-header>
        <h5 class="modal-title">
          {{ `${$t(typeProductForm)} ${$t('TitleMaster.Product')}` }}
        </h5>
        <div class="modal-actions">
          <feather-icon
            icon="MinusIcon"
            class="cursor-pointer"
            @click="(busy === false) ? $emit('update:shall-show-product-form-modal', false) : false"
          />
          <feather-icon
            icon="XIcon"
            class="ml-1 cursor-pointer"
            @click="(busy === false) ? onDiscard() : false"
          />
        </div>
      </template>

      <!-- Modal Footer -->
      <template #modal-footer>
        <!-- Footer: Left Content -->
        <div>
          <b-button
            variant="outline-secondary"
            type="reset"
            class="mt-2 mr-1"
            @click.prevent="resetLocalForm"
          >
            {{ $t('Reset') }}
          </b-button>
          <b-button
            v-if="tabForm !== 0"
            variant="outline-secondary"
            class="mt-2 mr-1"
            @click="onBack"
          >
            <feather-icon
              icon="ChevronLeftIcon"
              size="12"
            />
            <span class="ml-25 align-middle">
              {{ $t('Back') }}
            </span>
          </b-button>
          <b-button
            v-if="(typeProductForm !== 'View')"
            ref="submit"
            :variant="(typeProductForm === 'Add')? `primary` : `outline-primary`"
            class="mt-2 mr-1"
            :disabled="busy"
            @click="onSubmit"
          >
            <span
              class="ml-25 align-middle"
            >
              {{ (tabForm === 4 && typeProductForm === 'Add')? $t('Save Data') : $t('Next') }}
            </span>
            <feather-icon
              v-if="tabForm !== 1"
              icon="ChevronRightIcon"
              size="12"
            />
          </b-button>
        </div>

        <!-- Footer: Right Content -->
        <div>
          <b-button
            v-if="(typeProductForm === 'Edit')"
            variant="primary"
            class="mt-2 mr-1"
            :disabled="busy"
            @click="onSubmitEdit"
          >
            <feather-icon
              icon="SaveIcon"
              size="12"
            />
            <span
              class="ml-25 align-middle"
            >
              {{ $t('Save Data') }}
            </span>
          </b-button>
          <!-- Discard -->
          <b-button
            variant="outline-danger"
            class="mt-2 mr-1"
            :disabled="busy"
            @click="(busy === false) ? onDiscard() : false"
          >
            <feather-icon
              icon="XCircleIcon"
              size="12"
            />
            <span
              class="ml-25 align-middle"
            >
              {{ $t('Close') }}
            </span>
          </b-button>
        </div>
      </template>

      <!-- Modal: Body -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <div>
          <b-form
            ref="refForm"
            class="position-relative"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetLocalForm"
          >
            <b-tabs
              v-model="tabForm"
              pills
              nav-wrapper-class="col-12"
              content-class="col-12 col-md-12 mt-1 mt-md-0"
            >
              <!-- tab product -->
              <b-tab active>
                <template #title>
                  <feather-icon
                    icon="InfoIcon"
                    size="18"
                    class="mr-50"
                  />
                  <span class="font-weight-bold">{{ $t('FormProduct.Information') }}</span>
                </template>

                <validation-observer
                  ref="refInformationObserver"
                >
                  <product-form-information
                    v-if="productData.information"
                    :information-data="productData.information"
                    :type-product-form="typeProductForm"
                    :busy="busy"
                  />
                </validation-observer>
              </b-tab>

              <!-- tab approver -->
              <b-tab>
                <template #title>
                  <feather-icon
                    icon="UserCheckIcon"
                    size="18"
                    class="mr-50"
                  />
                  <span class="font-weight-bold">{{ $t('FormProduct.Approver') }}</span>
                </template>

                <product-form-approver
                  v-if="productData.approver"
                  :approver-data="productData.approver"
                  :type-product-form="typeProductForm"
                  :busy="busy"
                />
              </b-tab>

              <!-- tab fee -->
              <b-tab>
                <template #title>
                  <feather-icon
                    icon="HexagonIcon"
                    size="18"
                    class="mr-50"
                  />
                  <span class="font-weight-bold">{{ $t('FormProduct.Fee') }}</span>
                </template>

                <product-form-fee
                  v-if="productData.fee"
                  :fee-data="productData.fee"
                  :type-product-form="typeProductForm"
                  :busy="busy"
                />
              </b-tab>

              <!-- tab spec -->
              <b-tab>
                <template #title>
                  <feather-icon
                    icon="MenuIcon"
                    size="18"
                    class="mr-50"
                  />
                  <span class="font-weight-bold">{{ $t('FormProduct.Spec') }}</span>
                </template>

                <product-form-spec
                  v-if="productData.spec"
                  :spec-data="productData.spec"
                  :type-product-form="typeProductForm"
                  :busy="busy"
                />
              </b-tab>

              <!-- tab image -->
              <b-tab>
                <template #title>
                  <feather-icon
                    icon="ImageIcon"
                    size="18"
                    class="mr-50"
                  />
                  <span class="font-weight-bold">{{ $t('FormProduct.Image') }}</span>
                </template>

                <product-form-image
                  v-if="productData.image"
                  :image-data="productData.image"
                  :type-product-form="typeProductForm"
                  :busy="busy"
                  :product-id="productId"
                />
              </b-tab>

            </b-tabs>

          </b-form>

        </div>

      </validation-observer>

      <b-overlay
        :show="busy"
        no-wrap
        @shown="onShown"
        @hidden="onHidden"
      >
        <template #overlay>
          <div
            v-if="processing"
            class="text-center p-2 bg-primary text-light rounded"
          >
            <feather-icon
              icon="UploadCloudIcon"
              size="20"
            />
            <div class="mb-2">
              {{ `${$t('Processing')}...` }}
            </div>
            <b-progress
              min="1"
              max="10"
              :value="counter"
              variant="success"
              height="6px"
              class="mx-n1"
            />
          </div>
          <div
            v-else
            ref="dialog"
            tabindex="-1"
            role="dialog"
            aria-modal="false"
            aria-labelledby="form-confirm-label"
            class="text-center p-3"
          >
            <b-card-text
              class="font-weight-bolder"
            >
              {{ $t('Are you sure?') }}
            </b-card-text>
            <div class="d-flex">
              <b-button
                variant="outline-danger"
                class="mr-3"
                @click="onCancel"
              >
                {{ $t('Cancel') }}
              </b-button>
              <b-button
                variant="outline-success"
                @click="onOK"
              >
                {{ $t('Ok') }}
              </b-button>
            </div>
          </div>
        </template>
      </b-overlay>
    </b-modal>
  </b-overlay>
</template>

<script>
import {
  BButton,
  BForm,
  BOverlay,
  BProgress,
  BCardText,
  BTabs,
  BTab,
} from 'bootstrap-vue'
import {
  ValidationObserver,
} from 'vee-validate'
import { required } from '@validations'
import {
  onUnmounted,
} from '@vue/composition-api'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import storeProduct from './storeProduct'

import ProductFormInformation from './ProductFormInformation.vue'
import ProductFormFee from './ProductFormFee.vue'
import ProductFormSpec from './ProductFormSpec.vue'
import ProductFormImage from './ProductFormImage.vue'
import ProductFormApprover from './ProductFormApprover.vue'

export default {
  components: {
    ProductFormInformation,
    ProductFormFee,
    ProductFormSpec,
    ProductFormImage,
    ProductFormApprover,

    BButton,
    BForm,
    BOverlay,
    BProgress,
    BCardText,
    BTabs,
    BTab,

    ValidationObserver,
    FeatherIcon,
  },
  model: {
    prop: 'shallShowProductFormModal',
    event: 'update:shall-show-product-form-modal',
  },
  props: {
    shallShowProductFormModal: {
      type: Boolean,
      required: true,
    },
    typeProductForm: {
      type: String,
      default: 'Add',
    },
    productId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      showOverlay: false,
      required,
      busy: false,
      processing: false,
      counter: 1,
      interval: null,
      productData: {},
      tabForm: 0,
    }
  },
  watch: {
    shallShowProductFormModal() {
      if (this.shallShowProductFormModal === true) {
        if (this.typeProductForm === 'Add') {
          this.getDefaultProduct()
        } else if (this.typeProductForm === 'Edit') {
          this.getProduct()
        } else if (this.typeProductForm === 'View') {
          this.getProduct()
        }
      }
    },
  },
  methods: {
    onShown() {
      // Focus the dialog prompt
      this.$refs.dialog.focus()
    },
    onHidden() {
      this.$refs.submit.focus()
    },
    onSubmit() {
      if (this.tabForm === 0) {
        this.$refs.refInformationObserver.validate().then(success => {
          if (success) {
            // to next tab
            this.tabForm += 1
          }
        })
      } else if (this.tabForm === 1) {
        this.tabForm += 1
      } else if (this.tabForm === 2) {
        this.tabForm += 1
      } else if (this.tabForm === 3) {
        this.tabForm += 1
      } else if (this.tabForm === 4) {
        this.$refs.refInformationObserver.validate().then(success => {
          if (!success) {
            this.$nextTick(() => {
              this.tabForm = 0
            })
          } else {
            this.processing = false
            this.busy = true
          }
        })
      }
    },
    onSubmitEdit() {
      this.$refs.refInformationObserver.validate().then(success => {
        if (!success) {
          this.$nextTick(() => {
            this.tabForm = 0
          })
        } else {
          this.processing = false
          this.busy = true
        }
      })
    },
    onCancel() {
      this.busy = false
    },
    onOK() {
      this.processing = true
      if (this.typeProductForm === 'Add') {
        const params = {
          information: this.productData.information,
          fee: this.productData.fee,
          spec: this.productData.spec,
          approver: this.productData.approver,
        }
        store.dispatch('store-product-form/addProduct', params)
          .then(response => {
            let uploadFound = 0
            const uploadImage = this.productData.image.image
            uploadImage.forEach(item => {
              if (item.file !== undefined) {
                uploadFound += 1
              }
            })

            if (uploadFound > 0) {
              this.uploadImageProductImage(response.data.id)
            } else {
              // not upload image file
              const okMsg = this.$i18n.t('Process on successfully')
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: okMsg,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              },
              {
                position: 'bottom-right',
              })
              this.busy = false
              this.processing = false

              this.$swal({
                icon: 'success',
                title: this.$i18n.t('Added'),
                text: this.$i18n.t('Your data has been added'),
                timer: 3000,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })

              this.$emit('product-form-update')
              this.$emit('update:shall-show-product-form-modal', false)
            }
          })
          .catch(error => {
            const { response, message } = error
            if (response) {
              const errorMsg = this.$i18n.t(response?.data.error)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorMsg,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            } else if (message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error?.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            }
            this.busy = false
            this.processing = false
          })
      } else if (this.typeProductForm === 'Edit') {
        const data = {
          information: this.productData.information,
          fee: this.productData.fee,
          spec: this.productData.spec,
          approver: this.productData.approver,
        }
        store.dispatch('store-product-form/editProduct', { id: this.productId, data })
          .then(response => {
            let uploadFound = 0
            const uploadImage = this.productData.image.image
            uploadImage.forEach(item => {
              if (item.file !== undefined) {
                uploadFound += 1
              }
            })

            if (uploadFound > 0) {
              this.uploadImageProductImage(response.data.information.id)
            } else {
              const okMsg = this.$i18n.t('Process on successfully')
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: okMsg,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              },
              {
                position: 'bottom-right',
              })
              this.busy = false
              this.processing = false

              this.$emit('product-form-update')
              this.$emit('update:shall-show-product-form-modal', false)
            }
          })
          .catch(error => {
            const { response, message } = error
            if (response) {
              const errorMsg = this.$i18n.t(response?.data.error)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorMsg,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            } else if (message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error?.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            }
            this.busy = false
            this.processing = false
          })
      }
    },
    onDiscard() {
      this.$emit('discard-product-form')
      this.$emit('update:shall-show-product-form-modal', false)
    },
    resetLocalForm() {
      this.$refs.refFormObserver.reset()
      if (this.typeProductForm === 'Add') {
        this.getDefaultProduct()
      }
      this.tabForm = 0
      this.disabledFee = true
    },
    onBack() {
      this.tabForm -= 1
    },
    getDefaultProduct() {
      this.showOverlay = true
      store
        .dispatch('store-product-form/getDefaultProduct', { language: this.$i18n.locale })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.showOverlay = false
          this.productData = response.data
          this.setBreakTime()
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response?.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.showOverlay = false
        })
    },
    getProduct() {
      this.showOverlay = true
      store
        .dispatch('store-product-form/getProduct', { language: this.$i18n.locale, id: this.productId })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.showOverlay = false
          this.productData = response.data
          if (this.typeProductForm === 'Edit' || this.typeProductForm === 'View') {
            this.productData.image.image.forEach(img => {
              if (this.typeProductForm === 'Edit') {
                img.deletable = true
              } else {
                img.deletable = false
              }
            })
          }
          this.setBreakTime()
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response?.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.showOverlay = false
        })
    },
    setBreakTime() {
      if (this.productData.information.open_time !== '' && this.productData.information.open_time !== '00:00'
      && this.productData.information.close_time !== '' && this.productData.information.close_time !== '00:00') {
        const openArray = this.productData.information.open_time.split(':')
        const openHour = (openArray[0]) ? parseInt(openArray[0], 10) : null
        const openMin = (openArray[1]) ? parseInt(openArray[1], 10) : null
        const closeArray = this.productData.information.close_time.split(':')
        const closeHour = (closeArray[0]) ? parseInt(closeArray[0], 10) : null
        const closeMin = (closeArray[1]) ? parseInt(closeArray[1], 10) : null
        if (openHour !== null && openMin !== null && closeHour !== null && closeMin !== null) {
          const start = new Date()
          start.setHours(openHour, openMin, 0)
          const end = new Date()
          end.setHours(closeHour, closeMin, 0)

          if (start <= end) {
            this.productData.information.break_time_array = []
            while (start < end) {
              const first = start.toLocaleString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' })
              start.setMinutes(start.getMinutes() + 30)
              const last = start.toLocaleString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' })
              let valueBreakTime = false
              if (this.productData.information.break_time !== null && this.productData.information.break_time !== undefined && this.productData.information.break_time !== '') {
                if (this.productData.information.break_time.length > 0) {
                  if ((this.productData.information.break_time.find(e => e === first)) !== undefined) {
                    valueBreakTime = true
                  }
                }
              }
              const time = {
                time: first,
                value: valueBreakTime,
                show: `${first} - ${last}`,
              }
              this.productData.information.break_time_array.push(time)
            }
          }
        }
      }
    },
    uploadImageProductImage(id) {
      if (id !== '') {
        this.showOverlay = true

        const uploadImage = this.productData.image.image
        const fData = new FormData()
        uploadImage.forEach((item, index) => {
          fData.append(`file${index}`, item.file)
        })

        const params = {
          id,
          data: fData,
        }

        store.dispatch('store-product-form/uploadImageProductImage', params)
          // eslint-disable-next-line no-unused-vars
          .then(response => {
            this.showOverlay = true
            // console.log(response)
            const okMsg = this.$i18n.t('Process on successfully')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: okMsg,
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            })

            if (this.typeProductForm === 'Add') {
              this.$swal({
                icon: 'success',
                title: this.$i18n.t('Added'),
                text: this.$i18n.t('Your data has been added'),
                timer: 3000,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            }

            this.busy = false
            this.processing = false

            this.$emit('product-form-update')
            this.$emit('update:shall-show-product-form-modal', false)
          })
          .catch(error => {
            this.showOverlay = false
            const { response, message } = error
            if (response) {
              const errorMsg = this.$i18n.t(response?.data.error)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorMsg,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            } else if (message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error?.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            }
            this.busy = false
            this.processing = false
          })
      } else {
        const okMsg = this.$i18n.t('Process on error')
        this.$toast({
          component: ToastificationContent,
          props: {
            title: okMsg,
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        },
        {
          position: 'bottom-right',
        })
        this.busy = false
        this.processing = false
      }
    },
  },
  setup() {
    if (!store.hasModule('store-product-form')) store.registerModule('store-product-form', storeProduct)
    onUnmounted(() => {
      if (store.hasModule('store-product-form')) store.unregisterModule('store-product-form')
    })

    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation()

    return {
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/_variables';
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.modal{
  .line-height-info{
    padding-bottom: 0.6rem;
    &.end{
      padding-bottom: 0;
    }
  }
  .modal-header,
  .modal-header[class*='bg-'] {
    background-color: #fff !important;
  }
  .modal-body {
    background-color: $body-bg !important;
  }
}
.form-info-box{
  display: flex;
  padding: 0.6rem 1rem;
  background-color: #f8f8f8;
  background-clip: padding-box;
  border: 1px solid #f0f0f0;
  border-radius: 0.357rem;
  min-height: 38px;
}

.dark-layout {
  .modal{
    .line-height-info{
      padding-bottom: 0.6rem;
      &.end{
        padding-bottom: 0;
      }
    }
    .modal-header,
    .modal-header[class*='bg-'] {
      background-color: $theme-dark-body-bg !important;
    }
    .modal-body {
      background-color: $theme-dark-table-active-bg !important;
    }
  }
  .form-info-box{
    display: flex;
    padding: 0.6rem 1rem;
    background-color: $theme-dark-input-bg;
    background-clip: padding-box;
    border: 1px solid $theme-dark-border-color;
    border-radius: 0.357rem;
  }
}
</style>
