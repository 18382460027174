<template>
  <b-modal
    id="department-select"
    :visible="shallShowDepartmentSelectModal"
    :title="`${$t('Select')} ${$t('TitleMaster.Department')}`"
    footer-class="d-flex justify-content-between"
    size="lg"
    centered
    hide-footer
    no-close-on-backdrop
    @change="(val) => $emit('update:shall-show-department-select-modal', val)"
  >
    <b-overlay
      :show="showOverlay"
      rounded="sm"
    >
      <!-- Modal Header -->
      <template #modal-header>
        <h5 class="modal-title">
          {{ `${$t('Select')} ${$t('TitleMaster.Department')}` }}
        </h5>
        <div class="modal-actions">
          <feather-icon
            icon="MinusIcon"
            class="cursor-pointer"
            @click="(busy === false) ? $emit('update:shall-show-department-select-modal', false) : false"
          />
          <feather-icon
            icon="XIcon"
            class="ml-1 cursor-pointer"
            @click="(busy === false) ? onDiscard() : false"
          />
        </div>
      </template>

      <b-card
        class="p-0 m-0"
        style="margin-top: 0.8rem !important;"
      >
        <b-card-title style="margin-bottom: 0.4rem !important;">
          <div>
            <small
              v-if="departmentData.name !== undefined && departmentData.name !== ''"
            >{{ `${$t('Select')} ${$t('FormDepartment.Department')}` }}</small>
            <b-alert
              v-if="departmentData.name !== undefined && departmentData.name !== ''"
              show
              variant="success"
              style="margin-top: 0.2rem;"
            >
              <div class="alert-body">
                <feather-icon
                  class="mr-25"
                  icon="CheckCircleIcon"
                />
                <span class="ml-25">{{ departmentData.name }}</span>
              </div>
            </b-alert>
          </div>
        </b-card-title>

        <div style="margin: 0rem 0rem 1rem 0rem;">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">
                {{ $t('Showing {from} to {to} of {of} entries',
                      {from: dataMeta.from, to: dataMeta.to, of: dataMeta.of })
                }}
              </span>
            </b-col>
            <b-col
              cols="12"
              sm="12"
              md="6"
            >
              <div
                class="d-flex align-items-center justify-content-end w-100 mt-1 mt-md-0"
              >
                <b-form-input
                  v-model="q"
                  class="d-inline-block m-0"
                  :placeholder="`${$t('Search')}...`"
                />
              </div>
            </b-col>
          </b-row>
        </div>

        <div
          class="pb-75 pl-75 pr-75 pt-75"
          style="border: 1px solid #cccccc; background-color: #eeeeee; border-radius: 0.5rem;"
        >
          <b-row>
            <b-col
              v-for="tableCol in tableColumns"
              :key="tableCol.key"
              role="columnheader"
              :cols="tableCol.col"
            >
              <div>
                {{ tableCol.label }}
              </div>
            </b-col>
          </b-row>
        </div>
        <VuePerfectScrollbar
          class="scroll-area"
          :settings="settings"
          @ps-scroll-y="scrollHandle"
        >
          <b-row
            v-for="item in departmentList"
            :key="item.id"
            class="mt-1 mb-1 mr-1"
          >
            <b-col cols="3">
              <div style="margin-top: 0.3rem;">
                <b-list-group>
                  <b-list-group-item
                    style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                  >
                    <span style="margin-right: 0.6rem;">
                      <feather-icon
                        icon="HashIcon"
                        size="14"
                      />
                    </span>
                    <span style="font-size: 14px;">
                      <b-badge
                        pill
                        :variant="(skin === 'dark')? `light-info` : `info` "
                        class="text-capitalize"
                      >
                        {{ item.id }}
                      </b-badge>
                    </span>
                  </b-list-group-item>
                </b-list-group>
              </div>
            </b-col>

            <b-col cols="6">
              <div style="margin-top: 0.3rem;">
                <b-list-group>
                  <b-list-group-item
                    style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                  >
                    <span style="font-size: 14px;">
                      {{ item.name }}
                    </span>
                  </b-list-group-item>
                </b-list-group>
              </div>
            </b-col>

            <b-col cols="3">

              <div
                style="margin-top: 0.3rem;"
              >
                <b-button
                  v-if="checkSelect(item)"
                  size="sm"
                  variant="primary"
                  style="margin-left: 0rem;"
                  @click.stop="onSelect(item)"
                >
                  <feather-icon
                    icon="CheckCircleIcon"
                    size="14"
                    style="margin-right: 0.4rem;"
                  />
                  <span class="align-middle">{{ $t('Select') }}</span>
                </b-button>

                <b-button
                  v-else
                  size="sm"
                  variant="outline-success"
                  style="margin-left: 0rem;"
                >
                  <feather-icon
                    icon="CheckCircleIcon"
                    size="14"
                    style="margin-right: 0.4rem;"
                  />
                  <span class="align-middle">{{ $t('Selected') }}</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </VuePerfectScrollbar>
      </b-card>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BButton,
  BCard,
  BCardTitle,
  BRow,
  BCol,
  BAlert,
  BListGroup,
  BListGroupItem,
  BBadge,
  BFormInput,

  BOverlay,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {
  onUnmounted,
} from '@vue/composition-api'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import storeDepartment from './storeDepartment'

export default {
  components: {
    BButton,
    BCard,
    BCardTitle,
    BRow,
    BCol,
    BAlert,
    BListGroup,
    BListGroupItem,
    BBadge,
    BFormInput,
    BOverlay,

    VuePerfectScrollbar,
  },
  model: {
    prop: 'shallShowDepartmentSelectModal',
    event: 'update:shall-show-department-select-modal',
  },
  props: {
    shallShowDepartmentSelectModal: {
      type: Boolean,
      required: true,
    },
    departmentData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showOverlay: false,
      busy: false,
      skin: store.state.appConfig.layout.skin,
      settings: {
        maxScrollbarLength: 60,
      },
      perPage: 100,
      totalItem: 0,
      currentPage: 1,
      sortBy: 'name',
      isSortDirDesc: false,
      locale: 'th',
      q: '',
      departmentList: [],
      tableColumns: [],
    }
  },
  computed: {
    dataMeta() {
      const localItemsCount = this.departmentList.length
      return {
        from: (localItemsCount ? 1 : 0),
        to: localItemsCount,
        of: this.totalItem,
      }
    },
  },
  watch: {
    locale() {
      // this.refetchData()
    },
    q() {
      this.refetchData()
    },
    departmentData() {
      this.departmentList = []
      this.currentPage = 1
      this.getListDepartmentSelect()
    },
  },
  mounted() {
    const msgList = {
      id: `${this.$i18n.t('ID')}`,
      name: `${this.$i18n.t('FormDepartment.DepartmentName')}`,
      actions: this.$i18n.t('Actions'),
    }
    this.tableColumns = [
      {
        key: 'id', sortable: true, label: msgList.id, col: 3,
      },
      {
        key: 'name', sortable: true, label: msgList.name, col: 6,
      },
      { key: 'actions', label: msgList.actions, col: 3 },
    ]
  },
  methods: {
    getListDepartmentSelect() {
      this.showOverlay = true
      const params = {
        departmentId: this.departmentData.id,
        perPage: this.perPage,
        page: this.currentPage,
        sortBy: this.sortBy,
        sortDesc: this.isSortDirDesc,
        language: this.locale,
        q: this.q,
      }
      store
        .dispatch('department-store/getListDepartmentSelect', params)
        .then(response => {
          const { list, total } = response.data
          if (this.currentPage === 1) {
            // console.log('first')
            this.departmentList = list
          } else {
            // append
            // console.log('append')
            list.forEach(ll => {
              this.departmentList.push(ll)
            })
            // console.log(this.departmentList)
          }
          this.totalItem = total
          this.showOverlay = false
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response?.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.showOverlay = false
        })
    },
    refetchData() {
      this.departmentList = []
      this.currentPage = 1
      this.getListDepartmentSelect()
    },
    scrollHandle(event) {
      // console.log(event)

      // console.log(event.target.scrollTop)
      // console.log(event.target.clientHeight)
      // console.log(event.target.scrollHeight)
      if (event.target.scrollTop + event.target.clientHeight >= (event.target.scrollHeight - 2)) {
        // console.log('last scroll')
        if (this.showOverlay === false) {
          const totalListItem = this.departmentList.length
          // console.log(totalListItem)
          if (this.totalItem > totalListItem) {
            this.currentPage += 1
            // console.log('load more')
            this.getListDepartmentSelect()
          }
        }
      }
    },
    onSelect(item) {
      this.busy = true
      this.$emit('update-department-select', { select: item, department: this.departmentData })
      this.$emit('update:shall-show-department-select-modal', false)
      this.busy = false
    },
    checkSelect(item) {
      if (parseInt(item.id, 10) === parseInt(this.departmentData.id, 10)) {
        return false
      }
      return true
    },
  },
  setup() {
    if (!store.hasModule('department-store')) store.registerModule('department-store', storeDepartment)
    onUnmounted(() => {
      if (store.hasModule('department-store')) store.unregisterModule('department-store')
    })

    return {}
  },
}
</script>

<style>
.scroll-area {
  position: relative;
  margin: auto;
  width: 100%;
  height: 50vh;
}
</style>
