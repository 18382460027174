<template>
  <div>
    <b-card
      v-if="typeProductForm === 'Add' || typeProductForm === 'Edit'"
      border-variant="success"
      style="margin-bottom: 0.6rem;"
    >
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormSpecObserver"
      >
        <b-form
          ref="refFormSpec"
          class="position-relative"
          @submit.prevent="handleSubmit(onSubmitSpec)"
          @reset.prevent="resetForm"
        >
          <b-row>

            <!-- Field: Spec Template -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('FormProduct.SpecTemplate')"
                label-for="product-spec-template"
              >
                <validation-provider
                  #default="validationContext"
                  :name="$t('FormProduct.SpecTemplate')"
                  rules="required"
                >
                  <b-form-input
                    v-model="formSpecData.spec_template.id"
                    style="display: none;"
                    :state="getValidationState(validationContext)"
                  />
                  <b-input-group
                    class="m-0"
                    @click="selectSpecTemplate"
                  >
                    <b-input-group-prepend
                      is-text
                      style="cursor: pointer;"
                    >
                      <feather-icon
                        icon="PlusIcon"
                        size="12"
                      />
                    </b-input-group-prepend>
                    <b-form-input
                      id="product-spec-template"
                      ref="refSpecTemplate"
                      v-model="formSpecData.spec_template.name"
                      :disabled="true"
                      style="background: #f8f8f8 !important; cursor: pointer;"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ validationContext.errors[0] }}</small>
                </validation-provider>

              </b-form-group>
            </b-col>

            <!-- Field: Spec Name -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('FormProductSpec.Name')"
                label-for="product-spec-name"
              >
                <validation-provider
                  #default="validationContext"
                  :name="$t('FormProductSpec.Name')"
                  rules="required"
                >
                  <b-form-input
                    id="product-spec-name"
                    v-model="formSpecData.name"
                    :state="getValidationState(validationContext)"
                    :disabled="busy"
                  />
                  <small class="text-danger">{{ validationContext.errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Field: Spec Value::Boolen -->
            <b-col
              v-if="formSpecData.type === 'boolean'"
              cols="12"
              md="2"
            >
              <b-form-group
                :label="$t('FormProductSpec.Value')"
                label-for="product-spec-value"
              >
                <validation-provider
                  #default="validationContext"
                  :name="$t('FormProductSpec.Value')"
                  rules="required"
                >
                  <b-form-checkbox
                    v-model="formSpecData.value"
                    class="custom-control-success"
                    name="check-button"
                    switch
                    inline
                    style="margin-top: 0.5rem; margin-right: 0.25rem; padding: 0rem 0rem 0.2rem 0rem;"
                  />
                  <span class="margin-top: 1rem; padding: 0rem 0rem 0.2rem 0rem;">
                    {{ (formSpecData.value === true)? $t('FormProductSpec.Support') : $t('FormProductSpec.Unsupport') }}
                  </span>
                  <small class="text-danger">{{ validationContext.errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Field: Spec Value::Number -->
            <b-col
              v-else-if="formSpecData.type === 'number'"
              cols="12"
              md="2"
            >
              <b-form-group
                :label="$t('FormProduct.Value')"
                label-for="product-spec-value"
              >
                <validation-provider
                  #default="validationContext"
                  :name="$t('FormProduct.Value')"
                  rules="required"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="product-spec-value"
                      v-model="formSpecData.value"
                      :state="getValidationState(validationContext)"
                      :disabled="busy"
                    />
                    <b-input-group-append is-text>
                      {{ (formSpecData.unit !== '')? formSpecData.unit : '' }}
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ validationContext.errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-else
              cols="12"
              md="2"
            >

              <b-form-group
                :label="$t('FormProduct.Value')"
                label-for="product-spec-value"
              >
                <span class="form-info-box">&nbsp;</span>
              </b-form-group>
            </b-col>

            <!-- Field: IsFilter -->
            <b-col
              cols="2"
            >
              <b-form-group
                :label="$t('Status')"
                label-for="product-spec-status"
              >
                <validation-provider
                  #default="validationContext"
                  :name="$t('Status')"
                  rules="required"
                >
                  <b-form-checkbox
                    v-model="formSpecData.is_filter"
                    class="custom-control-info"
                    name="check-button"
                    switch
                    inline
                    style="margin-top: 0.5rem; margin-right: 0.25rem; padding: 0rem 0rem 0.2rem 0rem;"
                  />
                  <span class="margin-top: 1rem; padding: 0rem 0rem 0.2rem 0rem;">
                    {{ (formSpecData.is_filter === true)? $t('FormProductSpec.IsFilter') : $t('FormProductSpec.NotFilter') }}
                  </span>
                  <small class="text-danger">{{ validationContext.errors[0] }}</small>
                </validation-provider>

              </b-form-group>
            </b-col>

          </b-row>

          <b-row>

            <!-- Field: Status -->
            <b-col
              cols="3"
            >
              <b-form-group
                :label="$t('Status')"
                label-for="product-spec-status"
              >
                <validation-provider
                  #default="validationContext"
                  :name="$t('Status')"
                  rules="required"
                >
                  <b-form-radio
                    v-model="formSpecData.status"
                    name="product-spec-status"
                    value="active"
                    class="custom-control-success cursor-pointer mt-50"
                    inline
                  >
                    <span class="cursor-pointer">{{ $t('Active') }}</span>
                  </b-form-radio>
                  <b-form-radio
                    v-model="formSpecData.status"
                    name="product-spec-status"
                    value="inactive"
                    class="custom-control-danger cursor-pointer mt-50"
                    inline
                  >
                    <span class="cursor-pointer">{{ $t('Inactive') }}</span>
                  </b-form-radio>
                  <small class="text-danger">{{ validationContext.errors[0] }}</small>
                </validation-provider>

              </b-form-group>
            </b-col>

            <!-- Field: Sort -->
            <b-col
              cols="4"
            >
              <b-form-group
                :label="$t('Sort')"
                label-for="product-spec-sort"
              >
                <validation-provider
                  #default="validationContext"
                  :name="$t('Sort')"
                  rules="required"
                >
                  <b-row class="mt-1">
                    <b-col cols="3">
                      <b-form-radio
                        v-model="formSpecData.sort_type"
                        name="product-spec-sort"
                        value="input"
                        class="custom-control-info cursor-pointer"
                        inline
                      >
                        <span class="cursor-pointer">{{ $t('Input') }}</span>
                      </b-form-radio>
                    </b-col>
                    <b-col cols="3">
                      <b-form-input
                        id="product-spec-sort"
                        v-model="formSpecData.sort"
                        :state="getValidationState(validationContext)"
                        :disabled="!(formSpecData.sort_type === 'input')"
                        class="mt-n75"
                        @keypress="IsNumber"
                      />
                    </b-col>
                    <b-col
                      cols="3"
                    >
                      <b-form-radio
                        v-model="formSpecData.sort_type"
                        name="product-spec-sort"
                        value="first"
                        class="custom-control-info cursor-pointer"
                        inline
                      >
                        <span class="cursor-pointer">{{ $t('First') }}</span>
                      </b-form-radio>
                    </b-col>
                    <b-col
                      cols="3"
                    >
                      <b-form-radio
                        v-model="formSpecData.sort_type"
                        name="product-spec-sort"
                        value="last"
                        class="custom-control-info cursor-pointer"
                        inline
                      >
                        <span class="cursor-pointer">{{ $t('Last') }}</span>
                      </b-form-radio>
                    </b-col>
                  </b-row>
                  <small class="text-danger">{{ validationContext.errors[0] }}</small>
                </validation-provider>

              </b-form-group>
            </b-col>
          </b-row>

          <!-- Action: Button -->
          <b-row class="mt-1">
            <b-col
              cols="12"
            >
              <div
                class="float-right"
              >
                <b-button
                  variant="outline-secondary"
                  type="reset"
                  class="mr-1"
                  @click.prevent="resetFormSpec"
                >
                  <feather-icon icon="RefreshCwIcon" />
                  {{ $t('Reset') }}
                </b-button>
                <b-button
                  ref="submit"
                  variant="primary"
                  :disabled="busy"
                  @click="onSubmitSpec"
                >
                  <feather-icon icon="PlusIcon" />
                  {{ $t('Add') }}
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>

      <b-overlay
        :show="busyConfirm"
        no-wrap
        @shown="onShown"
        @hidden="onHidden"
      >
        <template #overlay>
          <div
            v-if="processingConfirm"
            class="text-center p-2 bg-primary text-light rounded"
          >
            <feather-icon
              icon="UploadCloudIcon"
              size="20"
            />
            <div class="mb-2">
              {{ `${$t('Processing')}...` }}
            </div>
            <b-progress
              min="1"
              max="10"
              :value="counterConfirm"
              variant="success"
              height="6px"
              class="mx-n1"
            />
          </div>
          <div
            v-else
            ref="dialog"
            tabindex="-1"
            role="dialog"
            aria-modal="false"
            aria-labelledby="form-confirm-label"
            class="text-center p-3"
          >
            <b-card-text
              class="font-weight-bolder"
            >
              {{ $t('Are you sure?') }}
            </b-card-text>
            <div class="d-flex">
              <b-button
                variant="outline-danger"
                class="mr-3"
                @click="onCancel"
              >
                {{ $t('Cancel') }}
              </b-button>
              <b-button
                ref="dialogOk"
                variant="outline-success"
                @click="onOK"
              >
                {{ $t('Ok') }}
              </b-button>
            </div>
          </div>
        </template>
      </b-overlay>
    </b-card>

    <b-card
      border-variant="success"
      no-body
      style="margin-top:1em;"
    >
      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <b-col
            cols="12"
            md="12"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ `${$t('Total')} ${specData.table_spec.length} ${$t('Entries')}` }}</label>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refTableSpec"
        :items="specData.table_spec"
        responsive
        :fields="tableColumnSpec"
        primary-key="id"
        show-empty
        hover
        empty-text="No matching records found"
        class="position-relative"
      >
        <!-- Column: Sort -->
        <template #cell(sort)="data">
          <div class="text-nowrap">
            <span class="align-text-top">{{ data.item.sort }}</span>
          </div>
        </template>

        <!-- Column: Name -->
        <template #cell(name)="data">
          <div class="text-nowrap">
            <span class="align-text-top">
              {{ data.item.name }}
            </span>
          </div>
        </template>

        <!-- Column: Value -->
        <template #cell(value)="data">
          <div class="text-nowrap">
            <span
              v-if="data.item.type === 'boolean'"
              class="align-text-top"
            >
              <b-badge
                pill
                :variant="(data.item.value === '1') ? `light-success` : `light-danger`"
                class="px-1"
              >
                {{ (data.item.value === '1') ? $t('FormProductSpec.Support') : $t('FormProductSpec.Unsupport') }}
              </b-badge>
            </span>
            <span
              v-else-if="data.item.type === 'number'"
              class="align-text-top"
            >
              <b-badge
                pill
                variant="light-secondary"
                class="px-1"
              >
                {{ data.item.value }}
              </b-badge>
            </span>
          </div>
        </template>

        <!-- Column: Unit -->
        <template #cell(unit)="data">
          <div class="text-nowrap">
            <span class="align-text-top">
              {{ data.item.unit }}
            </span>
          </div>
        </template>

        <!-- Column: Type -->
        <template #cell(type)="data">
          <div class="text-nowrap">
            <span class="align-text-top">
              <b-badge
                pill
                variant="light-secondary"
                class="text-capitalize px-1"
              >
                {{ data.item.type }}
              </b-badge>
            </span>
          </div>
        </template>

        <!-- Column: IsFilter -->
        <template #cell(is_filter)="data">
          <div class="text-nowrap">
            <span class="align-text-top">
              <b-badge
                pill
                :variant="(data.item.is_filter === true) ? `light-info` : `light-danger`"
                class="px-1"
              >
                {{ (data.item.is_filter === true)? $t('FormProductSpec.IsFilter') : $t('FormProductSpec.NotFilter') }}
              </b-badge>
            </span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <!-- Status -->
          <div style="margin-top: 0.3rem;">
            <span>
              <b-badge
                pill
                :variant="`light-${isStatusVariant(data.item.status)}`"
                class="text-capitalize"
              >
                {{ $t(isStatusToText(data.item.status)) }}
              </b-badge>
            </span>
          </div>
        </template>

        <!-- Column: Actions -->
        <template
          v-if="typeProductForm === 'Add' || typeProductForm === 'Edit'"
          #cell(actions)="data"
        >
          <div class="text-nowrap">
            <a
              class="action-icon"
              @click.stop="removeSpec(data.item)"
            >
              <feather-icon
                icon="TrashIcon"
                variant="light-success"
                size="16"
                class="cursor-pointer mr-1"
                @click="removeSpec(data.item)"
              />
            </a>
          </div>
        </template>

      </b-table>
    </b-card>

    <!-- Spec Template Select -->
    <spec-template-select
      v-model="showSpecTemplateSelectModal"
      :title="`${$t('Select')} ${$t('FormProduct.SpecTemplate')}`"
      :spec-template-data="specTemplateData"
      @update-spec-template-select="updateSpecTemplateSelect"
      @discard-spec-template-select="discardSpecTemplateSelect"
    />

  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormInput,
  BTable,
  BButton,
  BOverlay,
  BForm,
  BCardText,
  BBadge,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormRadio,
  BProgress,
  BFormCheckbox,
} from 'bootstrap-vue'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import SpecTemplateSelect from '@/views/master/spec_template/SpecTemplateSelect.vue'
import tableProduct from './tableProduct'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BTable,
    BButton,
    BOverlay,
    BForm,
    BCardText,
    BBadge,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormRadio,
    BProgress,
    BFormCheckbox,

    ValidationProvider,
    ValidationObserver,
    FeatherIcon,
    SpecTemplateSelect,
  },
  props: {
    typeProductForm: {
      type: String,
      default: 'Add',
    },
    specData: {
      type: Object,
      default: () => {},
    },
    busy: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formSpecData: {
        spec_template: {
          id: 0,
          name: '',
          value: '',
          unit: '',
          type: '',
        },
        name: '',
        value: '',
        unit: '',
        type: '',
        is_filter: false,
        sort: 1,
        sort_type: 'last',
        status: 'active',
      },
      tableColumnSpec: [
        { key: 'sort', sortable: true, label: this.$i18n.t('Sort') },
        { key: 'name', sortable: false, label: this.$i18n.t('FormSpecTemplate.Name') },
        { key: 'value', sortable: false, label: this.$i18n.t('FormSpecTemplate.Value') },
        { key: 'unit', sortable: false, label: this.$i18n.t('FormSpecTemplate.Unit') },
        { key: 'type', sortable: false, label: this.$i18n.t('FormSpecTemplate.Type') },
        { key: 'is_filter', sortable: false, label: this.$i18n.t('FormSpecTemplate.IsFilter') },
        { key: 'status', sortable: false, label: this.$i18n.t('FormProduct.Status') },
        // { key: 'actions', sortable: false, label: this.$i18n.t('Actions') },
      ],
      busyConfirm: false,
      processingConfirm: false,
      counterConfirm: 1,
      isAction: '',
      showSpecTemplateSelectModal: false,
      specTemplateData: {},
    }
  },
  watch: {
    // eslint-disable-next-line func-names
    'formSpecData.sort_type': function (value) {
      if (value === 'first') {
        this.formSpecData.sort = 1
      } else if (value === 'last') {
        // sort last
        this.formSpecData.sort = this.specData.table_spec.length + 1
      }
    },
  },
  mounted() {
    if (this.typeProductForm === 'Add' || this.typeProductForm === 'Edit') {
      this.tableColumnSpec.push({ key: 'actions', sortable: false, label: this.$i18n.t('Actions') })
    }
  },
  methods: {
    onShown() {
      this.$refs.dialogOk.focus()
    },
    onHidden() {
      this.$refs.submit.focus()
    },
    clearFormSpec() {
      this.formSpecData = {
        spec_template: {
          id: 0,
          name: '',
          value: '',
          unit: '',
          type: '',
        },
        name: '',
        value: '',
        unit: '',
        type: '',
        is_filter: false,
        sort: 1,
        sort_type: 'last',
        status: 'active',
      }
    },
    resetFormSpec() {
      this.processingConfirm = false
      this.busyConfirm = true
      this.isAction = 'reset'
    },
    onSubmitSpec() {
      this.$refs.refFormSpecObserver.validate().then(success => {
        if (success) {
          this.processingConfirm = false
          this.busyConfirm = true
          this.isAction = 'add'
        }
      })
    },
    onCancel() {
      this.busyConfirm = false
    },
    onOK() {
      this.processingConfirm = true
      if (this.isAction === 'add') {
        this.addSpec()
      } else if (this.isAction === 'reset') {
        this.clearFormSpec()
        this.$refs.refFormSpecObserver.reset()
        this.busyConfirm = false
        this.processingConfirm = false
      }
    },
    IsNumber(event) {
      if (!/\d/.test(event.key)) return event.preventDefault()
      return null
    },
    // eslint-disable-next-line no-unused-vars
    updateSpecTemplateSelect(data) {
      this.$nextTick(() => {
        this.formSpecData.spec_template.id = data.select.id
        this.formSpecData.spec_template.name = data.select.name
        this.formSpecData.spec_template.value = data.select.value
        this.formSpecData.spec_template.unit = data.select.unit
        this.formSpecData.spec_template.type = data.select.type
        this.specTemplateData = this.formSpecData.spec_template

        this.formSpecData.name = data.select.name
        if (data.select.type === 'boolean') {
          if (data.select.value === 'true') {
            this.formSpecData.value = true
          } else {
            this.formSpecData.value = false
          }
        } else {
          this.formSpecData.value = data.select.value
        }
        this.formSpecData.unit = data.select.unit
        this.formSpecData.type = data.select.type
      })
    },
    discardSpecTemplateSelect() {
    },
    selectSpecTemplate() {
      if (this.formSpecData.spec_template.value === '') {
        this.specTemplateData = {}
      } else {
        this.specTemplateData = {}
        this.specTemplateData = this.formSpecData.spec_template
      }
      this.showSpecTemplateSelectModal = true
    },
    addSpec() {
      const lastSort = this.specData.table_spec.length + 1
      if (this.formSpecData.sort_type === 'last') {
        this.formSpecData.sort = lastSort
        this.specData.table_spec.push(this.formSpecData)
      } else if (this.formSpecData.sort_type === 'first' || this.formSpecData.sort_type === 'input') {
        let sort = 1
        if (this.formSpecData.sort_type === 'input') {
          let inputSort = parseInt(this.formSpecData.sort, 10)
          if (inputSort === 0) {
            inputSort = 1
          } else if (inputSort > lastSort) {
            inputSort = lastSort
          }
          sort = inputSort
        }
        let cntSort = 1
        if (this.specData.table_spec.length === 0) {
          this.formSpecData.sort = 1
          this.specData.table_spec.push(this.formSpecData)
        } else if (sort >= lastSort) {
          this.formSpecData.sort = lastSort
          this.specData.table_spec.push(this.formSpecData)
        } else {
          const specArray = []
          this.specData.table_spec.forEach(spec => {
            if (cntSort === sort) {
              // new
              this.formSpecData.sort = sort
              specArray.push(this.formSpecData)
              cntSort += 1
            }
            // old
            spec.sort = cntSort
            specArray.push(spec)
            cntSort += 1
          })
          this.specData.table_spec = specArray
        }
      }
      this.clearFormSpec()
      this.$refs.refFormSpecObserver.reset()
      this.busyConfirm = false
      this.processingConfirm = false
      // sort last
      this.formSpecData.sort = this.specData.table_spec.length + 1
    },
    removeSpec(item) {
      this.$swal({
        title: this.$i18n.t('Are you sure?'),
        text: this.$i18n.t("You won't be able to revert this"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$i18n.t('Yes, remove it'),
        cancelButtonText: this.$i18n.t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value === true) {
          // remove
          let indexSort = 1
          const sort = parseInt(item.sort, 10)
          const specArray = []
          this.specData.table_spec.forEach(spec => {
            if (parseInt(spec.sort, 10) !== sort) {
              spec.sort = indexSort
              specArray.push(spec)
              indexSort += 1
            }
          })
          this.specData.table_spec = specArray
        }
      })
    },
  },
  setup() {
    const {
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
    } = formValidation()

    const {
      isStatusVariant,
      isStatusToText,
    } = tableProduct()

    return {
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,

      isStatusVariant,
      isStatusToText,
    }
  },

}
</script>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/_variables';
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.modal{
  .line-height-info{
    padding-bottom: 0.6rem;
    &.end{
      padding-bottom: 0;
    }
  }
  .modal-header,
  .modal-header[class*='bg-'] {
    background-color: #fff !important;
  }
  .modal-body {
    background-color: $body-bg !important;
  }
}
.form-info-box{
  display: flex;
  padding: 0.6rem 1rem;
  background-color: #f8f8f8;
  background-clip: padding-box;
  border: 1px solid #f0f0f0;
  border-radius: 0.357rem;
  min-height: 38px;
}

.dark-layout {
  .modal{
    .line-height-info{
      padding-bottom: 0.6rem;
      &.end{
        padding-bottom: 0;
      }
    }
    .modal-header,
    .modal-header[class*='bg-'] {
      background-color: $theme-dark-body-bg !important;
    }
    .modal-body {
      background-color: $theme-dark-table-active-bg !important;
    }
  }
  .form-info-box{
    display: flex;
    padding: 0.6rem 1rem;
    background-color: $theme-dark-input-bg;
    background-clip: padding-box;
    border: 1px solid $theme-dark-border-color;
    border-radius: 0.357rem;
  }
}
</style>
