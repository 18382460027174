<template>
  <div>
    <b-card
      v-if="typeProductForm === 'Add' || typeProductForm === 'Edit'"
      border-variant="success"
      style="margin-bottom: 0.6rem;"
    >
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormApproverObserver"
      >
        <b-form
          ref="refFormApprover"
          class="position-relative"
          @submit.prevent="handleSubmit(onSubmitApprover)"
          @reset.prevent="resetForm"
        >
          <b-row>

            <!-- Field: Approver User Select -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('FormProduct.Approver')"
                label-for="product-approver-user"
              >
                <validation-provider
                  #default="validationContext"
                  :name="$t('FormProduct.Approver')"
                  rules="required"
                >
                  <b-form-input
                    v-model="formApproverData.user.id"
                    style="display: none;"
                    :state="getValidationState(validationContext)"
                  />
                  <b-input-group
                    class="m-0"
                    @click.prevent="selectApproverUser"
                  >
                    <b-input-group-prepend
                      is-text
                      style="cursor: pointer;"
                    >
                      <feather-icon
                        icon="PlusIcon"
                        size="12"
                      />
                    </b-input-group-prepend>
                    <b-form-input
                      id="product-approver-user"
                      ref="refUser"
                      v-model="formApproverData.user.name"
                      :readonly="true"
                      style="background: #f8f8f8 !important; cursor: pointer;"
                      @click="selectApproverUser"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ validationContext.errors[0] }}</small>
                </validation-provider>

              </b-form-group>
            </b-col>

            <!-- Field: Approver User Name -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('FormProductApprover.Name')"
                label-for="product-user-name"
              >
                <b-form-input
                  id="product-user-name"
                  v-model="formApproverData.user_name"
                  :disabled="busy"
                />
              </b-form-group>
            </b-col>

            <!-- Field: Approver User Email -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('FormProductApprover.Email')"
                label-for="product-user-email"
              >
                <b-form-input
                  id="product-user-email"
                  v-model="formApproverData.user_email"
                  :disabled="busy"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <!-- Field: Status -->
            <b-col
              cols="3"
            >
              <b-form-group
                :label="$t('Status')"
                label-for="product-approver-status"
              >
                <validation-provider
                  #default="validationContext"
                  :name="$t('Status')"
                  rules="required"
                >
                  <b-form-radio
                    v-model="formApproverData.status"
                    name="product-approver-status"
                    value="active"
                    class="custom-control-success cursor-pointer mt-50"
                    inline
                  >
                    <span class="cursor-pointer">{{ $t('Active') }}</span>
                  </b-form-radio>
                  <b-form-radio
                    v-model="formApproverData.status"
                    name="product-approver-status"
                    value="inactive"
                    class="custom-control-danger cursor-pointer mt-50"
                    inline
                  >
                    <span class="cursor-pointer">{{ $t('Inactive') }}</span>
                  </b-form-radio>
                  <small class="text-danger">{{ validationContext.errors[0] }}</small>
                </validation-provider>

              </b-form-group>
            </b-col>

            <!-- Field: Sort -->
            <b-col
              cols="4"
            >
              <b-form-group
                :label="$t('Sort')"
                label-for="product-approver-sort"
              >
                <validation-provider
                  #default="validationContext"
                  :name="$t('Sort')"
                  rules="required"
                >
                  <b-row class="mt-1">
                    <b-col cols="3">
                      <b-form-radio
                        v-model="formApproverData.sort_type"
                        name="product-approver-sort"
                        value="input"
                        class="custom-control-info cursor-pointer"
                        inline
                      >
                        <span class="cursor-pointer">{{ $t('Input') }}</span>
                      </b-form-radio>
                    </b-col>
                    <b-col cols="3">
                      <b-form-input
                        id="product-approver-sort"
                        v-model="formApproverData.sort"
                        :state="getValidationState(validationContext)"
                        :disabled="!(formApproverData.sort_type === 'input')"
                        class="mt-n75"
                        @keypress="IsNumber"
                      />
                    </b-col>
                    <b-col
                      cols="3"
                    >
                      <b-form-radio
                        v-model="formApproverData.sort_type"
                        name="product-approver-sort"
                        value="first"
                        class="custom-control-info cursor-pointer"
                        inline
                      >
                        <span class="cursor-pointer">{{ $t('First') }}</span>
                      </b-form-radio>
                    </b-col>
                    <b-col
                      cols="3"
                    >
                      <b-form-radio
                        v-model="formApproverData.sort_type"
                        name="product-approver-sort"
                        value="last"
                        class="custom-control-info cursor-pointer"
                        inline
                      >
                        <span class="cursor-pointer">{{ $t('Last') }}</span>
                      </b-form-radio>
                    </b-col>
                  </b-row>
                  <small class="text-danger">{{ validationContext.errors[0] }}</small>
                </validation-provider>

              </b-form-group>
            </b-col>
          </b-row>

          <!-- Action: Button -->
          <b-row class="mt-1">
            <b-col
              cols="12"
            >
              <div
                class="float-right"
              >
                <b-button
                  variant="outline-secondary"
                  type="reset"
                  class="mr-1"
                  @click.prevent="resetFormApprover"
                >
                  <feather-icon icon="RefreshCwIcon" />
                  {{ $t('Reset') }}
                </b-button>
                <b-button
                  ref="submit"
                  variant="primary"
                  :disabled="busy"
                  @click="onSubmitApprover"
                >
                  <feather-icon icon="PlusIcon" />
                  {{ $t('Add') }}
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>

      <b-overlay
        :show="busyConfirm"
        no-wrap
        @shown="onShown"
        @hidden="onHidden"
      >
        <template #overlay>
          <div
            v-if="processingConfirm"
            class="text-center p-2 bg-primary text-light rounded"
          >
            <feather-icon
              icon="UploadCloudIcon"
              size="20"
            />
            <div class="mb-2">
              {{ `${$t('Processing')}...` }}
            </div>
            <b-progress
              min="1"
              max="10"
              :value="counterConfirm"
              variant="success"
              height="6px"
              class="mx-n1"
            />
          </div>
          <div
            v-else
            ref="dialog"
            tabindex="-1"
            role="dialog"
            aria-modal="false"
            aria-labelledby="form-confirm-label"
            class="text-center p-3"
          >
            <b-card-text
              class="font-weight-bolder"
            >
              {{ $t('Are you sure?') }}
            </b-card-text>
            <div class="d-flex">
              <b-button
                variant="outline-danger"
                class="mr-3"
                @click="onCancel"
              >
                {{ $t('Cancel') }}
              </b-button>
              <b-button
                ref="dialogOk"
                variant="outline-success"
                @click="onOK"
              >
                {{ $t('Ok') }}
              </b-button>
            </div>
          </div>
        </template>
      </b-overlay>
    </b-card>

    <b-card
      border-variant="success"
      no-body
      style="margin-top:1em;"
    >
      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <b-col
            cols="12"
            md="12"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ `${$t('Total')} ${approverData.table_approver.length} ${$t('Entries')}` }}</label>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refTableApprover"
        :items="approverData.table_approver"
        responsive
        :fields="tableColumnApprover"
        primary-key="id"
        show-empty
        hover
        empty-text="No matching records found"
        class="position-relative"
      >
        <!-- Column: Sort -->
        <template #cell(sort)="data">
          <div class="text-nowrap">
            <span class="align-text-top">{{ data.item.sort }}</span>
          </div>
        </template>

        <!-- Column: Name -->
        <template #cell(name)="data">
          <div class="text-nowrap">
            <span class="align-text-top">
              {{ data.item.user_name }}
            </span>
          </div>
        </template>

        <!-- Column: Email -->
        <template #cell(email)="data">
          <div class="text-nowrap">
            <span class="align-text-top">
              {{ data.item.user_email }}
            </span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <!-- Status -->
          <div style="margin-top: 0.3rem;">
            <span>
              <b-badge
                pill
                :variant="`light-${isStatusVariant(data.item.status)}`"
                class="text-capitalize"
              >
                {{ $t(isStatusToText(data.item.status)) }}
              </b-badge>
            </span>
          </div>
        </template>

        <!-- Column: Actions -->
        <template
          v-if="typeProductForm === 'Add' || typeProductForm === 'Edit'"
          #cell(actions)="data"
        >
          <div class="text-nowrap">
            <a
              class="action-icon"
              @click.stop="removeApprover(data.item)"
            >
              <feather-icon
                icon="TrashIcon"
                variant="light-success"
                size="16"
                class="cursor-pointer mr-1"
                @click="removeApprover(data.item)"
              />
            </a>
          </div>
        </template>

      </b-table>
    </b-card>

    <!-- User Select -->
    <role-user-select
      v-model="showApproverUserSelectModal"
      :title="`${$t('Select')} ${$t('FormProduct.Approver')}`"
      :role-user-data="approverUserData"
      :role-user-filter="approverUserFilter"
      @update-role-user-select="updateApproverUserSelect"
      @discard-role-user-select="discardApproverUserSelect"
    />

  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormInput,
  BTable,
  BButton,
  BOverlay,
  BForm,
  BCardText,
  BBadge,
  BInputGroup,
  BInputGroupPrepend,
  BFormRadio,
  BProgress,
} from 'bootstrap-vue'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import RoleUserSelect from '@/views/booking/role/RoleUserSelect.vue'
import tableProduct from './tableProduct'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BTable,
    BButton,
    BOverlay,
    BForm,
    BCardText,
    BBadge,
    BInputGroup,
    BInputGroupPrepend,
    BFormRadio,
    BProgress,

    ValidationProvider,
    ValidationObserver,
    FeatherIcon,
    RoleUserSelect,
  },
  props: {
    typeProductForm: {
      type: String,
      default: 'Add',
    },
    approverData: {
      type: Object,
      default: () => {},
    },
    busy: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formApproverData: {
        user: {
          id: 0,
          name: '',
          email: '',
        },
        user_id: 0,
        user_name: '',
        user_email: '',
        sort: 1,
        sort_type: 'last',
        status: 'active',
      },
      tableColumnApprover: [
        { key: 'sort', sortable: true, label: this.$i18n.t('Sort') },
        { key: 'name', sortable: false, label: this.$i18n.t('FormApprover.Name') },
        { key: 'email', sortable: false, label: this.$i18n.t('FormApprover.Email') },
        { key: 'status', sortable: false, label: this.$i18n.t('FormProduct.Status') },
        // { key: 'actions', sortable: false, label: this.$i18n.t('Actions') },
      ],
      busyConfirm: false,
      processingConfirm: false,
      counterConfirm: 1,
      isAction: '',
      showApproverUserSelectModal: false,
      approverUserData: {},
      approverUserFilter: {},
    }
  },
  watch: {
    // eslint-disable-next-line func-names
    'formApproverData.sort_type': function (value) {
      if (value === 'first') {
        this.formApproverData.sort = 1
      } else if (value === 'last') {
        // sort last
        this.formApproverData.sort = this.approverData.table_approver.length + 1
      }
    },
  },
  mounted() {
    if (this.typeProductForm === 'Add' || this.typeProductForm === 'Edit') {
      this.tableColumnApprover.push({ key: 'actions', sortable: false, label: this.$i18n.t('Actions') })
    }
  },
  methods: {
    onShown() {
      this.$refs.dialogOk.focus()
    },
    onHidden() {
      this.$refs.submit.focus()
    },
    clearFormApprover() {
      this.formApproverData = {
        user: {
          id: 0,
          name: '',
          email: '',
        },
        user_id: 0,
        user_name: '',
        user_email: '',
        sort: 1,
        sort_type: 'last',
        status: 'active',
      }
    },
    resetFormApprover() {
      this.processingConfirm = false
      this.busyConfirm = true
      this.isAction = 'reset'
    },
    onSubmitApprover() {
      this.$refs.refFormApproverObserver.validate().then(success => {
        if (success) {
          this.processingConfirm = false
          this.busyConfirm = true
          this.isAction = 'add'
        }
      })
    },
    onCancel() {
      this.busyConfirm = false
    },
    onOK() {
      this.processingConfirm = true
      if (this.isAction === 'add') {
        this.addApprover()
      } else if (this.isAction === 'reset') {
        this.clearFormApprover()
        this.$refs.refFormApproverObserver.reset()
        this.busyConfirm = false
        this.processingConfirm = false
      }
    },
    IsNumber(event) {
      if (!/\d/.test(event.key)) return event.preventDefault()
      return null
    },
    // eslint-disable-next-line no-unused-vars
    updateApproverUserSelect(data) {
      this.$nextTick(() => {
        this.formApproverData.user.id = data.select.user_id
        this.formApproverData.user.name = data.select.user_name
        this.formApproverData.user.email = data.select.user_email
        this.approverUserData.id = this.formApproverData.user.id
        this.approverUserData.name = this.formApproverData.user.name
        this.approverUserData.email = this.formApproverData.user.email

        this.formApproverData.user_id = data.select.user_id
        this.formApproverData.user_name = data.select.user_name
        this.formApproverData.user_email = data.select.user_email
      })
    },
    discardApproverUserSelect() {
    },
    selectApproverUser() {
      if (this.formApproverData.user.value === '') {
        this.approverUserData = {}
        this.approverUserData.role_code = 'approver'
      } else {
        this.approverUserData = {}
        this.approverUserData.id = this.formApproverData.user.value
        this.approverUserData.name = this.formApproverData.user.name
        this.approverUserData.role_code = 'approver'
      }
      this.showApproverUserSelectModal = true
    },
    addApprover() {
      const lastSort = this.approverData.table_approver.length + 1
      if (this.formApproverData.sort_type === 'last') {
        this.formApproverData.sort = lastSort
        this.approverData.table_approver.push(this.formApproverData)
      } else if (this.formApproverData.sort_type === 'first' || this.formApproverData.sort_type === 'input') {
        let sort = 1
        if (this.formApproverData.sort_type === 'input') {
          let inputSort = parseInt(this.formApproverData.sort, 10)
          if (inputSort === 0) {
            inputSort = 1
          } else if (inputSort > lastSort) {
            inputSort = lastSort
          }
          sort = inputSort
        }
        let cntSort = 1
        if (this.approverData.table_approver.length === 0) {
          this.formApproverData.sort = 1
          this.approverData.table_approver.push(this.formApproverData)
        } else if (sort >= lastSort) {
          this.formApproverData.sort = lastSort
          this.approverData.table_approver.push(this.formApproverData)
        } else {
          const specArray = []
          this.approverData.table_approver.forEach(spec => {
            if (cntSort === sort) {
              // new
              this.formApproverData.sort = sort
              specArray.push(this.formApproverData)
              cntSort += 1
            }
            // old
            spec.sort = cntSort
            specArray.push(spec)
            cntSort += 1
          })
          this.approverData.table_approver = specArray
        }
      }
      this.clearFormApprover()
      this.$refs.refFormApproverObserver.reset()
      this.busyConfirm = false
      this.processingConfirm = false
      // sort last
      this.formApproverData.sort = this.approverData.table_approver.length + 1
    },
    removeApprover(item) {
      this.$swal({
        title: this.$i18n.t('Are you sure?'),
        text: this.$i18n.t("You won't be able to revert this"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$i18n.t('Yes, remove it'),
        cancelButtonText: this.$i18n.t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value === true) {
          // remove
          let indexSort = 1
          const sort = parseInt(item.sort, 10)
          const specArray = []
          this.approverData.table_approver.forEach(spec => {
            if (parseInt(spec.sort, 10) !== sort) {
              spec.sort = indexSort
              specArray.push(spec)
              indexSort += 1
            }
          })
          this.approverData.table_approver = specArray
        }
      })
    },
  },
  setup() {
    const {
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
    } = formValidation()

    const {
      isStatusVariant,
      isStatusToText,
    } = tableProduct()

    return {
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,

      isStatusVariant,
      isStatusToText,
    }
  },

}
</script>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/_variables';
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.modal{
  .line-height-info{
    padding-bottom: 0.6rem;
    &.end{
      padding-bottom: 0;
    }
  }
  .modal-header,
  .modal-header[class*='bg-'] {
    background-color: #fff !important;
  }
  .modal-body {
    background-color: $body-bg !important;
  }
}
.form-info-box{
  display: flex;
  padding: 0.6rem 1rem;
  background-color: #f8f8f8;
  background-clip: padding-box;
  border: 1px solid #f0f0f0;
  border-radius: 0.357rem;
  min-height: 38px;
}

.dark-layout {
  .modal{
    .line-height-info{
      padding-bottom: 0.6rem;
      &.end{
        padding-bottom: 0;
      }
    }
    .modal-header,
    .modal-header[class*='bg-'] {
      background-color: $theme-dark-body-bg !important;
    }
    .modal-body {
      background-color: $theme-dark-table-active-bg !important;
    }
  }
  .form-info-box{
    display: flex;
    padding: 0.6rem 1rem;
    background-color: $theme-dark-input-bg;
    background-clip: padding-box;
    border: 1px solid $theme-dark-border-color;
    border-radius: 0.357rem;
  }
}
</style>
