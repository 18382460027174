import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { t, t2 } from '@/@core/libs/i18n/utils'
import { $themeConfig } from '@themeConfig'

export default function tableProduct() {
  // Use toast
  const toast = useToast()

  const refProductTable = ref(null)

  const msgList = {
    errorfetch: t2('Error fetching {module} list', { module: t('TitleMaster.Product') }),
    type_code: `${t('FormProduct.ProductType')} » ${t('FormProduct.Category')} » ${t('FormProduct.Department')}`,
    name: `${t('FormProduct.Name')} » ${t('FormProduct.Description')}`,
    use_with: `${t('FormProduct.UseWith')} » ${t('FormProduct.Approver')}`,
    time: `${t('FormProduct.DayOff')} » ${t('FormProduct.Time')} ${t('FormProduct.Open/Close')} » ${t('FormProduct.BreakTime')}`,
    actions: t('Actions'),
  }

  // Table Handlers
  const tableColumns = [
    {
      key: 'type_code', sortable: true, label: msgList.type_code, tdClass: 'align-top',
    },
    {
      key: 'name', sortable: true, label: msgList.name, tdClass: 'align-top',
    },
    {
      key: 'use_with', sortable: false, label: msgList.use_with, tdClass: 'align-top',
    },
    {
      key: 'time', sortable: false, label: msgList.time, tdClass: 'align-top',
    },
    { key: 'actions', label: msgList.actions },
  ]

  // Table Handlers
  const tableLongColumns = [
    { key: 'information.id', sortable: true, label: `${t('FormProduct.Id')}` },
    { key: 'information.product_type.name', sortable: true, label: `${t('FormProduct.ProductType')}` },
    { key: 'information.category.name', sortable: true, label: `${t('FormProduct.Category')}` },
    { key: 'information.code', sortable: true, label: `${t('FormProduct.Code')}` },
    { key: 'information.name', sortable: true, label: `${t('FormProduct.Name')}` },
    { key: 'status', sortable: true, label: `${t('FormProduct.Status')}` },
    { key: 'actions', label: msgList.actions },
  ]

  const tableColumnStyle = key => {
    if (key === 'type_code') return 'width: 20%'
    if (key === 'name') return 'width: 30%'
    if (key === 'use_with') return 'width: 20%'
    if (key === 'time') return 'width: 20%'
    if (key === 'actions') return 'width: 10%'
    return ''
  }

  const perPage = ref(10)
  const totalProducts = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [2, 5, 10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('type_code')
  const isSortDirDesc = ref(false)
  const locale = ref('th')
  const layout = ref('list')

  const dataMeta = computed(() => {
    const localItemsCount = refProductTable.value ? refProductTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalProducts.value,
    }
  })

  const refetchData = () => {
    if (refProductTable.value !== null) {
      refProductTable.value.refresh()
    }
  }

  watch([currentPage, perPage, searchQuery, locale], () => {
    refetchData()
  })

  const getListProduct = (ctx, callback) => {
    const params = {
      q: searchQuery.value,
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      language: locale.value,
    }
    store
      .dispatch('store-product/getListProduct', params)
      .then(response => {
        const { list, total } = response.data
        callback(list)
        totalProducts.value = total
        params.layout = layout.value
        localStorage.setItem(`${$themeConfig.app.session}-filter-product-list`, JSON.stringify(params))
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: msgList.errorfetch,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        },
        {
          position: 'bottom-right',
        })
      })
  }

  const isStatusVariant = isstatus => {
    if (isstatus === 'active') return 'success'
    if (isstatus === 'inactive') return 'danger'
    return 'danger'
  }

  const isStatusToText = isstatus => {
    if (isstatus === 'active') return 'Active'
    if (isstatus === 'inactive') return 'Inactive'
    return ''
  }

  const textFirstUpper = text => text.slice(0, 1).toUpperCase() + text.slice(1, text.length)

  return {
    getListProduct,
    tableColumns,
    tableLongColumns,
    tableColumnStyle,
    perPage,
    currentPage,
    totalProducts,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    locale,
    layout,
    refProductTable,

    refetchData,
    isStatusVariant,
    isStatusToText,
    textFirstUpper,
  }
}
