<template>
  <div
    v-if="$can('read', 'ModuleMaster.Product')"
    style="height: inherit"
  >
    <b-overlay
      :show="showOverlay"
      rounded="sm"
    >
      <!-- Table Container Card -->
      <b-card
        no-body
      >

        <div class="m-2">

          <!-- Table Top -->
          <b-row>

            <!-- Per Page -->
            <b-col
              cols="12"
              sm="12"
              md="6"
            >
              <label>{{ $t('Entries') }}</label>
              <v-select
                v-model="perPage"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block ml-50 mr-1"
              />
            </b-col>

            <!-- Search -->
            <b-col
              cols="12"
              sm="12"
              md="6"
            >
              <div class="d-flex align-items-center justify-content-end w-100 mt-1 mt-md-0">
                <b-button
                  v-if="$can('create', 'ModuleMaster.Product')"
                  variant="primary"
                  class="w-50"
                  @click="addProductForm"
                >
                  {{ `${$t('Add')} ${$t('TitleMaster.Product')}` }}
                </b-button>
                <b-button
                  :variant="(layout === 'list')? 'primary' : 'outline-primary'"
                  class="ml-50"
                  @click="onChangeLayout"
                >
                  <feather-icon icon="ListIcon" />
                </b-button>
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1 ml-50"
                  :placeholder="`${$t('Search')}...`"
                />
              </div>
            </b-col>
          </b-row>

        </div>
        <b-table
          ref="refProductTable"
          :items="getListProduct"
          responsive
          :fields="(layout === 'list')? tableLongColumns : tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          hover
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
          class="position-relative"
          selectable
          select-mode="multi"
        >
          <!-- Table Column Stye -->
          <template #table-colgroup="scope">
            <col
              v-for="field in scope.fields"
              :key="field.key"
              :style="tableColumnStyle(field.key)"
            >
          </template>

          <!-- Column: Type Code -->
          <template #cell(type_code)="data">
            <!-- Product Type -->
            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.4rem 0.6rem 0.2rem 0.6rem;"
                  class="top-bar-title-bg"
                >
                  <feather-icon
                    icon="ArchiveIcon"
                    class="mt-n25"
                  />
                  <span style="font-size: 14px; font-weight: bold;">
                    {{ $t('FormProduct.ProductType') }} »
                  </span>
                </b-list-group-item>
                <b-list-group-item
                  style="padding: 0.4rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span
                    v-if="data.item.information.product_type"
                    style="font-size: 16px;"
                  >
                    {{ `${data.item.information.product_type.name} (${data.item.information.product_type.code})` }}
                  </span>
                </b-list-group-item>
              </b-list-group>
            </div>
            <!-- Category -->
            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.4rem 0.6rem 0.2rem 0.6rem;"
                  class="top-bar-title-bg"
                >
                  <feather-icon
                    icon="PackageIcon"
                    class="mt-n25"
                  />
                  <span style="font-size: 14px; font-weight: bold;">
                    {{ $t('FormProduct.Category') }} »
                  </span>
                </b-list-group-item>
                <b-list-group-item
                  style="padding: 0.4rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="font-size: 16px;">{{ data.item.information.category.name }}</span>
                </b-list-group-item>
              </b-list-group>
            </div>
            <!-- Department -->
            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.4rem 0.6rem 0.2rem 0.6rem;"
                  class="top-bar-title-bg"
                >
                  <feather-icon
                    icon="OctagonIcon"
                    class="mt-n25"
                  />
                  <span style="font-size: 14px; font-weight: bold;">
                    {{ $t('FormProduct.Department') }} »
                  </span>
                </b-list-group-item>
                <b-list-group-item
                  style="padding: 0.4rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="font-size: 16px;">{{ data.item.information.department.name }}</span>
                </b-list-group-item>
              </b-list-group>
            </div>
          </template>

          <!-- Column: Name -->
          <template #cell(name)="data">
            <!-- Code -->
            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.4rem 0.6rem 0.2rem 0.6rem;"
                  class="top-bar-title-bg"
                >
                  <feather-icon
                    icon="HashIcon"
                    class="mt-n25"
                  />
                  <span style="font-size: 14px; font-weight: bold;">
                    {{ $t('FormProduct.Code') }} »
                  </span>
                </b-list-group-item>
                <b-list-group-item
                  style="padding: 0.4rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="font-size: 16px;">
                    <b-badge
                      pill
                      variant="light-success"
                      class="px-1"
                    >
                      {{ data.item.information.code }}
                    </b-badge>
                  </span>
                </b-list-group-item>
              </b-list-group>
            </div>
            <!-- Name -->
            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.4rem 0.6rem 0.2rem 0.6rem;"
                  class="top-bar-title-bg"
                >
                  <feather-icon
                    icon="InfoIcon"
                    class="mt-n25"
                  />
                  <span style="font-size: 14px; font-weight: bold;">
                    {{ $t('FormProduct.Name') }} »
                  </span>
                </b-list-group-item>
                <b-list-group-item
                  style="padding: 0.4rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="font-size: 16px;">
                    {{ data.item.information.name }}
                  </span>
                </b-list-group-item>
              </b-list-group>
            </div>
            <!-- Description -->
            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.4rem 0.6rem 0.2rem 0.6rem;"
                  class="top-bar-title-bg"
                >
                  <feather-icon
                    icon="MenuIcon"
                    class="mt-n25"
                  />
                  <span style="font-size: 14px; font-weight: bold;">
                    {{ $t('FormProduct.Description') }} »
                  </span>
                </b-list-group-item>
                <b-list-group-item
                  style="padding: 0.4rem 0.6rem 0.2rem 0.6rem; font-size: 16px;"
                >
                  <!-- eslint-disable-next-line vue/no-v-html -->
                  <span v-html="data.item.information.description_strip" />
                </b-list-group-item>
              </b-list-group>
            </div>
          </template>

          <!-- Column: Use with / Approver -->
          <template #cell(use_with)="data">
            <!-- Use with -->
            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.4rem 0.6rem 0.2rem 0.6rem;"
                  class="top-bar-title-bg"
                >
                  <feather-icon
                    icon="BookmarkIcon"
                    class="mt-n25"
                  />
                  <span style="margin-right: 0.6rem; font-weight: bold;">
                    {{ $t('FormProduct.UseWith') }} »
                  </span>
                </b-list-group-item>
                <b-list-group-item
                  style="padding: 0.6rem 0.6rem 0.4rem 0.6rem;"
                >
                  <span
                    v-if="data.item.information.is_booking === true"
                    style="font-size: 18px;"
                  >
                    <b-badge
                      pill
                      variant="light-warning"
                      class="px-1"
                    >
                      {{ (data.item.information.is_booking === true)? $t('FormProduct.IsBooking') : '' }}
                    </b-badge>
                  </span>
                  <span
                    v-if="data.item.information.is_renting === true"
                    style="font-size: 18px;"
                    class="ml-50"
                  >
                    <b-badge
                      pill
                      variant="light-info"
                      class="px-1"
                    >
                      {{ (data.item.information.is_renting === true)? $t('FormProduct.IsRenting') : '' }}
                    </b-badge>
                  </span>
                  <span
                    v-if="data.item.information.is_for_staff === true"
                    style="font-size: 18px;"
                    class="ml-50"
                  >
                    <b-badge
                      pill
                      variant="light-success"
                      class="px-1"
                    >
                      {{ (data.item.information.is_for_staff === true)? $t('FormProduct.IsForStaff') : '' }}
                    </b-badge>
                  </span>
                </b-list-group-item>
              </b-list-group>
            </div>
            <!-- Approver -->
            <div
              v-if="data.item.information.approver_user_name !== undefined"
              style="margin-top: 0.3rem;"
            >
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.4rem 0.6rem 0.2rem 0.6rem;"
                  class="top-bar-title-bg"
                >
                  <feather-icon
                    icon="UserIcon"
                    class="mt-n25"
                  />
                  <span style="font-size: 14px; font-weight: bold;">
                    {{ $t('FormProduct.Approver') }} »
                  </span>
                </b-list-group-item>
                <b-list-group-item
                  style="padding: 0.4rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="font-size: 16px;">
                    <b-badge
                      pill
                      variant="light-secondary"
                      class="px-1"
                    >
                      {{ data.item.information.approver_user_name }}
                    </b-badge>

                  </span>
                </b-list-group-item>
              </b-list-group>
            </div>
            <!-- Status -->
            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.6rem 0.6rem 0.4rem 0.6rem;"
                >
                  <feather-icon
                    icon="EyeIcon"
                    class="mt-n25"
                  />
                  <span style="margin-right: 0.6rem; font-weight: bold;">
                    {{ $t('Status') }} »
                  </span>
                  <span style="font-size: 18px;">
                    <b-badge
                      pill
                      :variant="`light-${isStatusVariant(data.item.information.status)}`"
                      class="text-capitalize"
                    >
                      {{ $t(isStatusToText(data.item.information.status)) }}
                    </b-badge>
                  </span>
                </b-list-group-item>
              </b-list-group>
            </div>
          </template>

          <!-- Column: Close Sat/Sun / Open/Close/Break Time -->
          <template #cell(time)="data">
            <!-- Close Saturday/Sunday -->
            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.4rem 0.6rem 0.2rem 0.6rem;"
                  class="top-bar-title-bg"
                >
                  <feather-icon
                    icon="CalendarIcon"
                    class="mt-n25"
                  />
                  <span style="margin-right: 0.6rem; font-weight: bold;">
                    {{ $t('FormProduct.DayOff') }} »
                  </span>
                </b-list-group-item>
                <b-list-group-item
                  style="padding: 0.6rem 0.6rem 0.4rem 0.6rem;"
                >
                  <span style="margin-right: 0.6rem; font-weight: bold;">
                    {{ $t('FormProduct.Saturday') }} »
                  </span>
                  <span
                    style="font-size: 18px;"
                  >
                    <b-badge
                      pill
                      :variant="(data.item.information.close_saturday === true)? 'light-danger' : 'light-success'"
                      class="px-1"
                    >
                      {{ (data.item.information.close_saturday === true)? $t('FormProduct.Close') : $t('FormProduct.Open') }}
                    </b-badge>
                  </span>
                </b-list-group-item>
                <b-list-group-item
                  style="padding: 0.6rem 0.6rem 0.4rem 0.6rem;"
                >
                  <span style="margin-right: 0.6rem; font-weight: bold;">
                    {{ $t('FormProduct.Sunday') }} »
                  </span>
                  <span
                    style="font-size: 18px;"
                  >
                    <b-badge
                      pill
                      :variant="(data.item.information.close_sunday === true)? 'light-danger' : 'light-success'"
                      class="px-1"
                    >
                      {{ (data.item.information.close_sunday === true)? $t('FormProduct.Close') : $t('FormProduct.Open') }}
                    </b-badge>
                  </span>
                </b-list-group-item>
              </b-list-group>
            </div>
            <!-- Open/Close -->
            <div
              style="margin-top: 0.3rem;"
            >
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.4rem 0.6rem 0.2rem 0.6rem;"
                  class="top-bar-title-bg"
                >
                  <feather-icon
                    icon="ClockIcon"
                    class="mt-n25"
                  />
                  <span style="font-size: 14px; font-weight: bold;">
                    {{ `${$t('FormProduct.Time')} ${$t('FormProduct.Open/Close')}` }} »
                  </span>
                </b-list-group-item>
                <b-list-group-item
                  style="padding: 0.4rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="font-size: 16px;">
                    <b-badge
                      pill
                      variant="light-secondary"
                      class="px-1"
                    >
                      {{ data.item.information.open_time }}
                    </b-badge>
                  </span>
                  -
                  <span style="font-size: 16px;">
                    <b-badge
                      pill
                      variant="light-secondary"
                      class="px-1"
                    >
                      {{ data.item.information.close_time }}
                    </b-badge>
                  </span>
                </b-list-group-item>
              </b-list-group>
            </div>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">

            <div class="text-nowrap">
              <a
                class="action-icon"
                @click.stop="editProductForm(data)"
              >
                <feather-icon
                  v-if="$can('write', 'ModuleMaster.Product')"
                  :id="`product-row-${data.item.id}-edit-icon`"
                  icon="EditIcon"
                  class="cursor-pointer mr-1"
                  size="16"
                />
                <b-tooltip
                  v-if="$can('write', 'ModuleMaster.Product')"
                  :title="`${$t('Edit')} ${$t('ModuleMaster.Product')}`"
                  :target="`product-row-${data.item.id}-edit-icon`"
                  :delay="{ show: 2500, hide: 50 }"
                  placement="left"
                />
              </a>

              <a
                class="action-icon"
                @click.stop="viewProductForm(data)"
              >
                <feather-icon
                  v-if="$can('read', 'ModuleMaster.Product')"
                  :id="`product-row-${data.item.id}-view-icon`"
                  icon="EyeIcon"
                  size="16"
                  class="cursor-pointer mr-1"
                />
                <b-tooltip
                  v-if="$can('read', 'ModuleMaster.Product')"
                  :title="`${$t('View')} ${$t('ModuleMaster.Product')}`"
                  :target="`product-row-${data.item.id}-view-icon`"
                  :delay="{ show: 2500, hide: 50 }"
                  placement="left"
                />
              </a>

              <a
                class="action-icon"
                @click.stop="deleteProductForm(data)"
              >
                <feather-icon
                  v-if="$can('delete', 'ModuleMaster.Product')"
                  :id="`product-row-${data.item.id}-delete-icon`"
                  icon="TrashIcon"
                  variant="light-success"
                  size="16"
                  class="cursor-pointer mr-1"
                />
                <b-tooltip
                  v-if="$can('delete', 'ModuleMaster.Product')"
                  :title="`${$t('Delete')} ${$t('ModuleMaster.Product')}`"
                  :target="`product-row-${data.item.id}-delete-icon`"
                  :delay="{ show: 2500, hide: 50 }"
                  placement="right"
                />
              </a>

            </div>
          </template>

          <template #cell(status)="data">
            <!-- Status -->
            <div style="margin-top: 0.3rem;">
              <span style="font-size: 18px;">
                <b-badge
                  pill
                  :variant="`light-${isStatusVariant(data.item.information.status)}`"
                  class="text-capitalize"
                >
                  {{ $t(isStatusToText(data.item.information.status)) }}
                </b-badge>
              </span>
            </div>
          </template>

        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">
                {{ $t('Showing {from} to {to} of {of} entries',
                      {from: dataMeta.from, to: dataMeta.to, of: dataMeta.of })
                }}
              </span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentPage"
                :total-rows="totalProducts"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>

      <product-form
        v-model="shallShowProductFormModal"
        :product-id="productId"
        :type-product-form="typeProductForm"
        @product-form-update="updateProductForm"
        @discard-product-form="discardProductForm"
      />
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput,
  BTable, BButton,
  BBadge, BPagination, BTooltip, BListGroup, BListGroupItem,
  BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  ref,
  onUnmounted,
} from '@vue/composition-api'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import { $themeConfig } from '@themeConfig'
import tableProduct from './tableProduct'
import storeProduct from './storeProduct'
import ProductForm from './ProductForm.vue'

export default {
  components: {
    ProductForm,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BBadge,
    BPagination,
    BTooltip,
    BListGroup,
    BListGroupItem,
    BButton,
    BOverlay,

    vSelect,
    FeatherIcon,
  },
  data() {
    return {
      showOverlay: false,
      productId: 0,
      dialog: false,
      userData: JSON.parse(localStorage.getItem(`${$themeConfig.app.commonSession}-user-data`)),
    }
  },
  mounted() {
    this.locale = this.$i18n.locale
  },
  methods: {
    getDefaultProduct() {
      this.showOverlay = true
      store
        .dispatch('store-product/getDefaultProduct', { language: this.$i18n.locale })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.showOverlay = false
          this.productData = response.data
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response?.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.showOverlay = false
        })
    },
    addProductForm() {
      this.productId = 0
      this.typeProductForm = 'Add'
      this.shallShowProductFormModal = true
    },
    editProductForm(data) {
      this.productId = data.item.information.id
      this.typeProductForm = 'Edit'
      this.shallShowProductFormModal = true
    },
    viewProductForm(data) {
      this.productId = data.item.information.id
      this.typeProductForm = 'View'
      this.shallShowProductFormModal = true
    },
    deleteProductForm(data) {
      this.$swal({
        title: this.$i18n.t('Are you sure?'),
        text: this.$i18n.t("You won't be able to revert this"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$i18n.t('Yes, delete it'),
        cancelButtonText: this.$i18n.t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value === true) {
          store.dispatch('store-product/deleteProduct', { id: data.item.information.id, userId: this.userData.id })
            .then(() => {
              const okMsg = this.$i18n.t('Process on successfully')
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: okMsg,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              },
              {
                position: 'bottom-right',
              })
              this.$swal({
                icon: 'success',
                title: this.$i18n.t('Deleted'),
                text: this.$i18n.t('Your data has been deleted'),
                timer: 3000,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.refetchData()
            })
            .catch(error => {
              const { response, message } = error
              if (response) {
                let errorMsg = this.$i18n.t(response?.data.error)
                if (errorMsg === '') {
                  errorMsg = this.$i18n.t(response?.data.message)
                }
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: errorMsg,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                },
                {
                  position: 'bottom-right',
                })
              } else if (message) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error?.message,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                },
                {
                  position: 'bottom-right',
                })
              }
            })
        }
      })
    },
    updateProductForm() {
      this.refetchData()
    },
    discardProductForm() {
      this.refetchData()
    },
    onChangeLayout() {
      console.log(this.layout)
      if (this.layout === 'list') {
        this.layout = 'full'
      } else {
        this.layout = 'list'
      }
      const params = {
        q: this.searchQuery,
        perPage: this.perPage,
        page: this.currentPage,
        sortBy: this.sortBy,
        sortDesc: this.isSortDirDesc,
        language: this.ocale,
        layout: this.layout,
      }
      localStorage.setItem(`${$themeConfig.app.session}-filter-product-list`, JSON.stringify(params))
    },
  },
  setup() {
    const shallShowProductFormModal = ref(false)
    const typeProductForm = ref('')

    if (!store.hasModule('store-product')) store.registerModule('store-product', storeProduct)
    onUnmounted(() => {
      if (store.hasModule('store-product')) store.unregisterModule('store-product')
    })

    const {
      getListProduct,
      tableColumns,
      tableLongColumns,
      tableColumnStyle,
      perPage,
      currentPage,
      totalProducts,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      locale,
      layout,
      refProductTable,
      refetchData,
      isStatusVariant,
      isStatusToText,
    } = tableProduct()

    const filterList = JSON.parse(localStorage.getItem(`${$themeConfig.app.session}-filter-product-list`))
    if (filterList !== null) {
      searchQuery.value = (filterList.searchQuery !== null && filterList.searchQuery !== undefined) ? filterList.searchQuery : ''
      perPage.value = (filterList.perPage !== null && filterList.perPage !== undefined) ? filterList.perPage : ''
      currentPage.value = (filterList.page !== null && filterList.page !== undefined) ? filterList.page : ''
      sortBy.value = (filterList.sortBy !== null && filterList.sortBy !== undefined) ? filterList.sortBy : ''
      isSortDirDesc.value = (filterList.sortDesc !== null && filterList.sortDesc !== undefined) ? filterList.sortDesc : ''
      layout.value = (filterList.layout !== null && filterList.layout !== undefined) ? filterList.layout : 'list'
    }

    return {
      shallShowProductFormModal,
      typeProductForm,

      getListProduct,
      tableColumns,
      tableLongColumns,
      tableColumnStyle,
      perPage,
      currentPage,
      totalProducts,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      locale,
      layout,
      refProductTable,
      refetchData,
      isStatusVariant,
      isStatusToText,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.product-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';
@import '~@core/scss/base/components/variables';

.dark-layout {
  div{
    .b-overlay {
      .bg-light {
        background-color: $theme-dark-body-bg !important;
      }
    }
    .swal2-popup {
      background-color: $theme-dark-body-bg !important;
    }
  }
  .table.b-table > tbody .b-table-row-selected.table-active td {
    background-color: $theme-dark-table-active-bg;
  }
  .table.b-table.table-hover > tbody > tr.table-active:hover td,
  .table.b-table.table-hover > tbody > tr.table-active:hover th {
    background-image: linear-gradient($theme-dark-table-active-hover-bg, $theme-dark-table-active-hover-bg);
  }
  .top-bar-title-bg{
    background-color: $theme-dark-modal-header-bg !important;
  }
}
.top-bar-title-bg{
  background-color: $theme-light-modal-header-bg !important;
}
.b-table-selectable{
  .feather{
    font-size: 1.3rem;
  }
}
.action-icon {
  :hover {
    color: #429dc8 !important;
  }
}
</style>
