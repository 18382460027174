import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getDefaultProductCategory(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/master/get_default_product_category', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getListProductCategory(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/master/get_list_product_category', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getProductCategory(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/master/get_product_category', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addProductCategory(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/master/add_product_category', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editProductCategory(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/master/edit_product_category', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteProductCategory(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/master/delete_product_category', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getProductCategoryOptions(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/master/product_category_options', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getListProductCategorySelect(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/master/get_list_product_category_select', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
