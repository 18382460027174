import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getDefaultSpecTemplate(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/master/get_default_spec_template', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getListSpecTemplate(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/master/get_list_spec_template', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getSpecTemplate(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/master/get_spec_template', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addSpecTemplate(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/master/add_spec_template', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editSpecTemplate(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/master/edit_spec_template', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteSpecTemplate(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/master/delete_spec_template', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getSpecTemplateOptions(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/master/spec_template_options', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getListSpecTemplateSelect(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/master/get_list_spec_template_select', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
