<template>
  <div>
    <b-card
      v-if="(typeProductForm === 'Add' || typeProductForm === 'Edit')"
      style="margin-bottom: 0.6rem;"
    >
      <div>
        <b-row>
          <b-col cols="12">
            <b-form-group
              :label="$t('FormProductImage.SelectImage')"
              label-for="product-image-image"
            >
              <van-uploader
                v-model="imageData.image"
                multiple
                accept="image/jpeg,image/png,image/heif"
                :max-size="maxSizeImage"
                :before-read="onBeforeReadImage"
                :after-read="onAfterReadImage"
                :preview-options="imageOptions"
                :before-delete="onBeforeDeleteImage"
                @oversize="onOversizeImage"
              >
                <template #preview-cover="{ file }">
                  <div class="preview-cover van-ellipsis">
                    {{ (file)? file.name : '' }}
                  </div>
                </template>
              </van-uploader>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card
      v-if="(typeProductForm === 'View')"
      style="margin-bottom: 0.6rem;"
    >
      <div>
        <b-row>
          <b-col cols="12">
            <b-form-group
              :label="$t('FormProductImage.ImageList')"
              label-for="product-image-image-list"
            >
              <van-uploader
                v-model="imageData.image"
                multiple
                accept="image/jpeg,image/png,image/heif"
                :preview-options="listOptions"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
} from 'bootstrap-vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import {
  Dialog,
  Toast,
} from 'vant'
import store from '@/store'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
  },
  props: {
    typeProductForm: {
      type: String,
      default: 'Add',
    },
    imageData: {
      type: Object,
      default: () => {},
    },
    busy: {
      type: Boolean,
      default: false,
    },
    productId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      maxSizeImage: 5000 * 1024,
      imageOptions: {
        closeable: true,
        deletable: true,
      },
      listOptions: {
        closeable: true,
        deletable: false,
      },
    }
  },
  // data: () => ({
  methods: {
    // eslint-disable-next-line no-unused-vars
    onOversizeImage(file) {
      Toast(this.$i18n.t('ValidateMessage.File size cannot exceed', { size: '5Mb' }))
    },
    onBeforeReadImage(file) {
      if (file.type !== 'image/jpeg' && file.type !== 'image/png' && file.type !== 'image/heif') {
        Toast({
          message: this.$i18n.t('Please upload an image in jpg/png/heif file'),
          icon: 'warning-o',
          position: 'middle',
          duration: 4000,
        })
        return false
      }
      return true
    },
    onAfterReadImage(file) {
      if (this.productId !== '' && this.productId !== undefined && this.productId > 0) {
        Dialog.confirm({
          title: this.$i18n.t('Confirmation'),
          message: this.$i18n.t('Do you want to upload image?'),
          cancelButtonText: this.$i18n.t('Cancel'),
          confirmButtonText: this.$i18n.t('Upload'),
          confirmButtonColor: 'blue',
        })
          .then(() => {
            // on confirm
            file.status = 'uploading'
            file.message = 'Uploading...'

            const fData = new FormData()
            fData.append('file0', file.file)

            const params = {
              id: this.productId,
              data: fData,
            }
            store.dispatch('store-product-form/uploadImageProductImage', params)
              // eslint-disable-next-line no-unused-vars
              .then(response => {
                // console.log(response)
                const { result } = response.data
                if (result.length > 0) {
                  file.url = result[0].url
                  file.product_id = result[0].product_id
                  file.file_name = result[0].file_name
                  delete file.file
                  delete file.content
                }
                file.status = 'uploaded'
                delete file.message
                Toast({
                  message: this.$i18n.t('Process on successfully'),
                  icon: 'success',
                  position: 'middle',
                  duration: 4000,
                })
              })
              .catch(() => {
                file.status = 'failed'
                file.message = 'Failed'
              })
          })
          .catch(() => {
            // on cancel
            file.status = 'failed'
            file.message = 'Wait Save'
          })
      }
    },
    onBeforeDeleteImage(file) {
      console.log(file)
      return new Promise((resolve, reject) => {
        if (file.status !== undefined && file.status === 'failed') {
          resolve()
        } else if (this.productId > 0) {
          Dialog.confirm({
            title: this.$i18n.t('Confirmation'),
            message: this.$i18n.t('Do you want to delete?'),
            cancelButtonText: this.$i18n.t('Cancel'),
            confirmButtonText: this.$i18n.t('Delete'),
            confirmButtonColor: 'red',
          })
            .then(() => {
              // on confirm
              file.status = 'uploading'
              file.message = 'Deleting...'
              resolve(true)
              const params = {
                product_id: this.productId,
                file_name: file.file.name,
              }
              store.dispatch('store-product-form/deleteImageProductImage', params)
                // eslint-disable-next-line no-unused-vars
                .then(response => {
                  // console.log(response)
                  file.status = ''
                  file.message = ''
                  Toast({
                    message: this.$i18n.t('Process on successfully'),
                    icon: 'success',
                    position: 'middle',
                    duration: 4000,
                  })
                  resolve(true)
                })
                .catch(error => {
                  file.status = 'failed'
                  file.message = 'Failed'
                  reject(error)
                })
            })
            .catch(error => {
            // on cancel
              reject(error)
            })
        } else {
          resolve()
        }
      })
    },
  },
  setup() {
    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation()

    return {

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
    }
  },

}
</script>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/_variables';
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.modal{
  .line-height-info{
    padding-bottom: 0.6rem;
    &.end{
      padding-bottom: 0;
    }
  }
  .modal-header,
  .modal-header[class*='bg-'] {
    background-color: #fff !important;
  }
  .modal-body {
    background-color: $body-bg !important;
  }
}
.form-info-box{
  display: flex;
  padding: 0.6rem 1rem;
  background-color: #f8f8f8;
  background-clip: padding-box;
  border: 1px solid #f0f0f0;
  border-radius: 0.357rem;
  min-height: 38px;
}

.dark-layout {
  .modal{
    .line-height-info{
      padding-bottom: 0.6rem;
      &.end{
        padding-bottom: 0;
      }
    }
    .modal-header,
    .modal-header[class*='bg-'] {
      background-color: $theme-dark-body-bg !important;
    }
    .modal-body {
      background-color: $theme-dark-table-active-bg !important;
    }
  }
  .form-info-box{
    display: flex;
    padding: 0.6rem 1rem;
    background-color: $theme-dark-input-bg;
    background-clip: padding-box;
    border: 1px solid $theme-dark-border-color;
    border-radius: 0.357rem;
  }
}
</style>

<style lang="scss">
.preview-cover {
  position: absolute;
  bottom: 0;
  box-sizing: border-box;
  width: 100%;
  padding: 4px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  background: rgba(0, 0, 0, 0.3);
}

.van-uploader__upload {
  width: 200px;
  height: 200px;
}
.van-uploader__preview-image {
  width: 200px;
  height: 200px;
}
</style>
