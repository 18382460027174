import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getDefaultProduct(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/master/get_default_product', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getListProduct(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/master/get_list_product', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getProduct(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/master/get_product', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addProduct(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/master/add_product', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editProduct(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/master/edit_product', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteProduct(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/master/delete_product', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    uploadImageProductImage(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/master/upload_image_product_image/${params.id}`, params.data, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteImageProductImage(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/master/delete_image_product_image', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getProductOptions(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/master/product_options', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getListProductSelect(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/master/get_list_product_select', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
