<template>
  <div>
    <b-card style="margin-bottom: 0.6rem;">
      <div>
        <b-row>
          <!-- Left -->
          <b-col cols="8">

            <b-row>
              <!-- Field: Type Code -->
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  v-if="(typeProductForm === 'Add' || typeProductForm === 'Edit')"
                  :label="$t('FormProduct.ProductType')"
                  label-for="product-type-code"
                >
                  <span class="form-info-box">{{ `${informationData.product_type.name}` }}</span>
                </b-form-group>

                <b-form-group
                  v-else
                  :label="$t('FormProduct.ProductType')"
                  label-for="product-type-code"
                >
                  <span class="form-info-box">{{ `${informationData.product_type.name}` }}</span>
                </b-form-group>
              </b-col>

              <!-- Field: Category -->
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  v-if="(typeProductForm === 'Add' || typeProductForm === 'Edit')"
                  :label="$t('FormProduct.ProductCategory')"
                  label-for="product-category"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('FormProduct.ProductCategory')"
                    rules="required"
                  >
                    <b-form-input
                      v-model="informationData.category.value"
                      style="display: none;"
                      :state="getValidationState(validationContext)"
                    />
                    <b-input-group
                      class="m-0"
                      @click.prevent="selectProductCategory"
                    >
                      <b-input-group-prepend
                        is-text
                        style="cursor: pointer;"
                      >
                        <feather-icon
                          icon="PlusIcon"
                          size="12"
                        />
                      </b-input-group-prepend>
                      <b-form-input
                        id="product-category"
                        ref="refCategory"
                        v-model="informationData.category.name"
                        :readonly="true"
                        style="background: #f8f8f8 !important; cursor: pointer;"
                        @click="selectProductCategory"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ validationContext.errors[0] }}</small>
                  </validation-provider>

                </b-form-group>

                <b-form-group
                  v-else
                  :label="$t('FormProduct.ProductCategory')"
                  label-for="product-category"
                >
                  <span class="form-info-box">{{ informationData.category.name }}</span>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <!-- Field: Department -->
              <b-col
                cols="12"
                md="12"
              >
                <b-form-group
                  v-if="(typeProductForm === 'Add' || typeProductForm === 'Edit')"
                  :label="$t('FormProduct.Department')"
                  label-for="product-department"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('FormProduct.Department')"
                    rules="required"
                  >
                    <b-form-input
                      v-model="informationData.department.value"
                      style="display: none;"
                      :state="getValidationState(validationContext)"
                    />
                    <b-input-group
                      class="m-0"
                      @click.prevent="selectDepartment"
                    >
                      <b-input-group-prepend
                        is-text
                        style="cursor: pointer;"
                      >
                        <feather-icon
                          icon="PlusIcon"
                          size="12"
                        />
                      </b-input-group-prepend>
                      <b-form-input
                        id="product-department"
                        v-model="informationData.department.name"
                        :readonly="true"
                        style="background: #f8f8f8 !important; cursor: pointer;"
                        @click="selectDepartment"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ validationContext.errors[0] }}</small>
                  </validation-provider>

                </b-form-group>

                <b-form-group
                  v-else
                  :label="$t('FormProduct.Department')"
                  label-for="product-department"
                >
                  <span class="form-info-box">{{ informationData.department.name }}</span>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <!-- Field: Code -->
              <b-col
                cols="12"
                md="4"
              >
                <b-form-group
                  v-if="(typeProductForm === 'Add' || typeProductForm === 'Edit')"
                  :label="$t('FormProduct.Code')"
                  label-for="product-code"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('FormProduct.Code')"
                    rules="required"
                  >
                    <b-form-input
                      id="product-code"
                      v-model="informationData.code"
                      :state="getValidationState(validationContext)"
                      :disabled="busy"
                    />
                    <small class="text-danger">{{ validationContext.errors[0] }}</small>
                  </validation-provider>

                </b-form-group>

                <b-form-group
                  v-else
                  :label="$t('FormProduct.Code')"
                  label-for="product-code"
                >
                  <span class="form-info-box">{{ informationData.code }}</span>
                </b-form-group>
              </b-col>

              <!-- Field: Name -->
              <b-col
                cols="12"
                md="8"
              >
                <b-form-group
                  v-if="(typeProductForm === 'Add' || typeProductForm === 'Edit')"
                  :label="$t('FormProduct.Name')"
                  label-for="product-name"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('FormProduct.Name')"
                    rules="required"
                  >
                    <b-form-input
                      id="product-name"
                      v-model="informationData.name"
                      :state="getValidationState(validationContext)"
                      :disabled="busy"
                    />
                    <small class="text-danger">{{ validationContext.errors[0] }}</small>
                  </validation-provider>

                </b-form-group>

                <b-form-group
                  v-else
                  :label="$t('FormProduct.Name')"
                  label-for="product-name"
                >
                  <span class="form-info-box">{{ informationData.name }}</span>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <!-- Field: Description -->
              <b-col
                cols="12"
                md="12"
              >
                <b-form-group
                  v-if="(typeProductForm === 'Add' || typeProductForm === 'Edit')"
                  :label="$t('FormProduct.Description')"
                  label-for="product-description"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('FormProduct.Description')"
                    rules="required"
                  >
                    <div class="description-editor">
                      <quill-editor
                        id="quil-content-description"
                        ref="refDescription"
                        v-model="informationData.description"
                        :options="descriptionOption"
                        :state="getValidationState(validationContext)"
                        :disabled="busy"
                        style="height: 300px;"
                      />
                      <div
                        id="quill-toolbar-description"
                        class="d-flex border-top-0"
                      >
                        <button class="ql-bold" />
                        <button class="ql-italic" />
                        <button class="ql-underline" />
                        <button class="ql-align" />
                        <button class="ql-link" />
                        <select class="ql-size">
                          <option value="small" />
                          <option selected />
                          <option value="large" />
                          <option value="huge" />
                        </select>
                      </div>
                    </div>
                    <small class="text-danger">{{ validationContext.errors[0] }}</small>
                  </validation-provider>

                </b-form-group>

                <b-form-group
                  v-else
                  :label="$t('FormProduct.Description')"
                  label-for="product-description"
                >
                  <quill-editor
                    v-model="informationData.description"
                    :options="descriptionViewOption"
                    :disabled="true"
                    style="border-bottom: 1px solid #cccccc !important;"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <!-- Right -->
          <b-col cols="4">

            <b-row>
              <!-- Field: Capacity -->
              <b-col
                cols="4"
              >
                <b-form-group
                  v-if="(typeProductForm === 'Add' || typeProductForm === 'Edit')"
                  :label="$t('FormProduct.Capacity')"
                  label-for="product-capacity"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('FormProduct.Capacity')"
                    rules="required"
                  >
                    <b-form-input
                      id="product-capacity"
                      v-model="informationData.capacity"
                      :state="getValidationState(validationContext)"
                      :disabled="busy"
                      @keypress="IsNumber"
                    />
                  </validation-provider>

                </b-form-group>

                <b-form-group
                  v-else
                  :label="$t('FormProduct.Capacity')"
                  label-for="product-capacity"
                >
                  <span class="form-info-box">{{ informationData.capacity }}</span>
                </b-form-group>
              </b-col>
              <!-- Field: Status -->
              <b-col
                cols="12"
                md="8"
              >
                <b-form-group
                  v-if="(typeProductForm === 'Add' || typeProductForm === 'Edit')"
                  :label="$t('Status')"
                  label-for="product-status"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('Status')"
                    rules="required"
                  >
                    <b-form-radio
                      v-model="informationData.status"
                      name="product-status"
                      value="active"
                      class="custom-control-success cursor-pointer"
                      inline
                    >
                      <span class="cursor-pointer">{{ $t('Active') }}</span>
                    </b-form-radio>
                    <b-form-radio
                      v-model="informationData.status"
                      name="product-status"
                      value="inactive"
                      class="custom-control-danger cursor-pointer"
                      inline
                    >
                      <span class="cursor-pointer">{{ $t('Inactive') }}</span>
                    </b-form-radio>
                    <small class="text-danger">{{ validationContext.errors[0] }}</small>
                  </validation-provider>

                </b-form-group>

                <b-form-group
                  v-else
                  :label="$t('Status')"
                  label-for="product-status"
                >
                  <span
                    class="form-info-box"
                    style="font-size: 18px;"
                  >
                    <b-badge
                      pill
                      :variant="`light-${isStatusVariant(informationData.status)}`"
                      class="px-1 py-50"
                    >
                      {{ $t(textFirstUpper(informationData.status)) }}
                    </b-badge>
                  </span>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <!-- Field: Is Fee - is_fee_booking, is_fee_renting -->
              <b-col
                cols="12"
              >
                <b-form-group
                  v-if="(typeProductForm === 'Add' || typeProductForm === 'Edit')"
                  :label="$t('FormProduct.IsFee')"
                  label-for="product-is-fee"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('FormProduct.IsFee')"
                  >
                    <div class="d-flex">
                      <b-form-checkbox
                        v-model="informationData.is_fee_booking"
                        class="custom-control-warning"
                        name="check-button"
                        switch
                        inline
                        style="margin-top: 0.5rem; padding: 0rem 0rem 0.2rem 0rem;"
                      >
                        <span class="margin-top: 1rem; padding: 0rem 0rem 0.2rem 0rem;">
                          {{ $t('FormProduct.IsFeeBooking') }}
                        </span>
                      </b-form-checkbox>
                      <b-form-checkbox
                        v-model="informationData.is_fee_renting"
                        class="custom-control-info"
                        name="check-button"
                        switch
                        style="margin-right: 1rem; margin-top: 0.5rem; padding: 0rem 0rem 0.2rem 0rem;"
                      >
                        <span class="margin-top: 1rem; padding: 0rem 0rem 0.2rem 0rem;">
                          {{ $t('FormProduct.IsFeeRenting') }}
                        </span>
                      </b-form-checkbox>
                    </div>
                    <small class="text-danger">{{ validationContext.errors[0] }}</small>
                  </validation-provider>

                </b-form-group>

                <b-form-group
                  v-else
                  :label="$t('FormProduct.IsFee')"
                  label-for="product-is-fee"
                >
                  <span class="form-info-box">
                    <span
                      v-if="informationData.is_fee_booking === true"
                      style="font-size: 18px;"
                    >
                      <b-badge
                        pill
                        variant="light-warning"
                        class="px-1"
                      >
                        {{ (informationData.is_fee_booking === true)? $t('FormProduct.IsFeeBooking') : '' }}
                      </b-badge>
                    </span>
                    <span
                      v-if="informationData.is_fee_renting === true"
                      style="font-size: 18px;"
                      class="ml-50"
                    >
                      <b-badge
                        pill
                        variant="light-info"
                        class="px-1"
                      >
                        {{ (informationData.is_fee_renting === true)? $t('FormProduct.IsFeeRenting') : '' }}
                      </b-badge>
                    </span>
                  </span>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <!-- Field: Use with - is_booking, is_renting, is_for_staff -->
              <b-col
                cols="12"
              >
                <b-form-group
                  v-if="(typeProductForm === 'Add' || typeProductForm === 'Edit')"
                  :label="$t('FormProduct.UseWith')"
                  label-for="product-use-with"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('FormProduct.UseWith')"
                  >
                    <div class="d-flex">
                      <b-form-checkbox
                        v-model="informationData.is_booking"
                        class="custom-control-warning"
                        name="check-button"
                        switch
                        inline
                        style="margin-top: 0.5rem; padding: 0rem 0rem 0.2rem 0rem;"
                      >
                        <span class="margin-top: 1rem; padding: 0rem 0rem 0.2rem 0rem;">
                          {{ $t('FormProduct.IsBooking') }}
                        </span>
                      </b-form-checkbox>
                      <b-form-checkbox
                        v-model="informationData.is_renting"
                        class="custom-control-info"
                        name="check-button"
                        switch
                        style="margin-right: 1rem; margin-top: 0.5rem; padding: 0rem 0rem 0.2rem 0rem;"
                      >
                        <span class="margin-top: 1rem; padding: 0rem 0rem 0.2rem 0rem;">
                          {{ $t('FormProduct.IsRenting') }}
                        </span>
                      </b-form-checkbox>
                      <b-form-checkbox
                        v-model="informationData.is_for_staff"
                        class="custom-control-success"
                        name="check-button"
                        switch
                        style="margin-right: 1rem; margin-top: 0.5rem; padding: 0rem 0rem 0.2rem 0rem;"
                      >
                        <span class="margin-top: 1rem; padding: 0rem 0rem 0.2rem 0rem;">
                          {{ $t('FormProduct.IsForStaff') }}
                        </span>
                      </b-form-checkbox>
                    </div>
                    <small class="text-danger">{{ validationContext.errors[0] }}</small>
                  </validation-provider>

                </b-form-group>

                <b-form-group
                  v-else
                  :label="$t('FormProduct.UseWith')"
                  label-for="product-use-with"
                >
                  <span class="form-info-box">
                    <span
                      v-if="informationData.is_booking === true"
                      style="font-size: 18px;"
                    >
                      <b-badge
                        pill
                        variant="light-warning"
                        class="px-1"
                      >
                        {{ (informationData.is_booking === true)? $t('FormProduct.IsBooking') : '' }}
                      </b-badge>
                    </span>
                    <span
                      v-if="informationData.is_renting === true"
                      style="font-size: 18px;"
                      class="ml-50"
                    >
                      <b-badge
                        pill
                        variant="light-info"
                        class="px-1"
                      >
                        {{ (informationData.is_renting === true)? $t('FormProduct.IsRenting') : '' }}
                      </b-badge>
                    </span>
                    <span
                      v-if="informationData.is_for_staff === true"
                      style="font-size: 18px;"
                      class="ml-50"
                    >
                      <b-badge
                        pill
                        variant="light-success"
                        class="px-1"
                      >
                        {{ (informationData.is_for_staff === true)? $t('FormProduct.IsForStaff') : '' }}
                      </b-badge>
                    </span>
                  </span>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <!-- Field: Full Day - Saturday/Sunday -->
              <b-col
                cols="12"
              >
                <b-form-group
                  v-if="(typeProductForm === 'Add' || typeProductForm === 'Edit')"
                  :label="`${$t('FormProduct.FullDay')} - ${$t('FormProduct.Saturday/Sunday')}`"
                  label-for="product-use-with"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="`${$t('FormProduct.FullDay')} - ${$t('FormProduct.Saturday/Sunday')}`"
                  >
                    <div class="d-flex">
                      <b-form-checkbox
                        v-model="informationData.is_full_day"
                        class="custom-control-success"
                        name="check-button"
                        switch
                        inline
                        style="margin-top: 0.5rem; padding: 0rem 0rem 0.2rem 0rem;"
                      >
                        <span class="margin-top: 1rem; padding: 0rem 0rem 0.2rem 0rem;">
                          {{ $t('FormProduct.IsFullDay') }}
                        </span>
                      </b-form-checkbox>
                      <b-form-checkbox
                        v-model="informationData.close_saturday"
                        class="custom-control-danger"
                        name="check-button"
                        switch
                        style="margin-right: 1rem; margin-top: 0.5rem; padding: 0rem 0rem 0.2rem 0rem;"
                      >
                        <span class="margin-top: 1rem; padding: 0rem 0rem 0.2rem 0rem;">
                          {{ $t('FormProduct.CloseSaturday') }}
                        </span>
                      </b-form-checkbox>
                      <b-form-checkbox
                        v-model="informationData.close_sunday"
                        class="custom-control-danger"
                        name="check-button"
                        switch
                        style="margin-right: 1rem; margin-top: 0.5rem; padding: 0rem 0rem 0.2rem 0rem;"
                      >
                        <span class="margin-top: 1rem; padding: 0rem 0rem 0.2rem 0rem;">
                          {{ $t('FormProduct.CloseSunday') }}
                        </span>
                      </b-form-checkbox>
                    </div>
                    <small class="text-danger">{{ validationContext.errors[0] }}</small>
                  </validation-provider>

                </b-form-group>

                <b-form-group
                  v-else
                  :label="`${$t('FormProduct.FullDay')} - ${$t('FormProduct.Saturday/Sunday')}`"
                  label-for="product-use-with"
                >
                  <span class="form-info-box">
                    <span
                      v-if="informationData.is_full_day === true"
                      style="font-size: 18px;"
                    >
                      <b-badge
                        pill
                        variant="light-success"
                        class="px-1"
                      >
                        {{ (informationData.is_full_day === true)? $t('FormProduct.IsFullDay') : '' }}
                      </b-badge>
                    </span>
                    <span
                      v-if="informationData.close_saturday === true"
                      style="font-size: 18px;"
                      class="ml-50"
                    >
                      <b-badge
                        pill
                        variant="light-danger"
                        class="px-1"
                      >
                        {{ (informationData.close_saturday === true)? $t('FormProduct.CloseSaturday') : '' }}
                      </b-badge>
                    </span>
                    <span
                      v-if="informationData.close_sunday === true"
                      style="font-size: 18px;"
                      class="ml-50"
                    >
                      <b-badge
                        pill
                        variant="light-danger"
                        class="px-1"
                      >
                        {{ (informationData.close_sunday === true)? $t('FormProduct.CloseSunday') : '' }}
                      </b-badge>
                    </span>
                  </span>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="informationData.is_full_day !== true">
              <!-- Field: OpenTime -->
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  v-if="(typeProductForm === 'Add' || typeProductForm === 'Edit')"
                  :label="$t('FormProduct.OpenTime')"
                  label-for="product-open-time"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('FormProduct.OpenTime')"
                  >
                    <b-form-timepicker
                      id="product-open-time"
                      v-model="informationData.open_time"
                      :state="getValidationState(validationContext)"
                      class="m-0"
                      :disabled="busy"
                      :hour12="false"
                      :label-close-button="$t('Close')"
                      :hide-header="true"
                      :minutes-step="30"
                      :placeholder="$t('FormProduct.OpenTime')"
                    />
                    <small class="text-danger">{{ validationContext.errors[0] }}</small>
                  </validation-provider>

                </b-form-group>

                <b-form-group
                  v-else
                  :label="$t('FormProduct.OpenTime')"
                  label-for="product-open-time"
                >
                  <span class="form-info-box">{{ informationData.open_time }}</span>
                </b-form-group>
              </b-col>

              <!-- Field: CloseTime -->
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  v-if="(typeProductForm === 'Add' || typeProductForm === 'Edit')"
                  :label="$t('FormProduct.CloseTime')"
                  label-for="product-close-time"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('FormProduct.CloseTime')"
                  >
                    <b-form-timepicker
                      id="product-close-time"
                      v-model="informationData.close_time"
                      :state="getValidationState(validationContext)"
                      class="m-0"
                      :disabled="busy"
                      :hour12="false"
                      :label-close-button="$t('Close')"
                      :hide-header="true"
                      :minutes-step="30"
                      :placeholder="$t('FormProduct.CloseTime')"
                    />
                    <small class="text-danger">{{ validationContext.errors[0] }}</small>
                  </validation-provider>

                </b-form-group>

                <b-form-group
                  v-else
                  :label="$t('FormProduct.CloseTime')"
                  label-for="product-close-time"
                >
                  <span class="form-info-box">{{ informationData.close_time }}</span>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="informationData.is_full_day !== true">
              <!-- Field: Break Time -->
              <b-col
                cols="12"
                md="12"
              >
                <b-form-group
                  v-if="(typeProductForm === 'Add' || typeProductForm === 'Edit')"
                  :label="$t('FormProduct.BreakTime')"
                  label-for="product-use-with"
                >
                  <b-row v-if="informationData.break_time_array.length > 0">
                    <b-col
                      v-for="(time, index) in informationData.break_time_array"
                      :key="index"
                      cols="6"
                    >
                      <div class="d-flex">
                        <b-form-checkbox
                          :key="index"
                          v-model="time.value"
                          class="custom-control-success"
                          name="check-button"
                          switch
                          inline
                          style="margin-top: 0.5rem; padding: 0rem 0rem 0.2rem 0rem;"
                        >
                          <span class="margin-top: 1rem; padding: 0rem 0rem 0.2rem 0rem;">
                            {{ time.show }}
                          </span>
                        </b-form-checkbox>
                      </div>
                    </b-col>
                  </b-row>
                </b-form-group>

                <b-form-group
                  v-else
                  :label="$t('FormProduct.BreakTime')"
                  label-for="product-use-with"
                >
                  <b-row v-if="informationData.break_time_array.length > 0">
                    <b-col
                      v-for="(time, index) in informationData.break_time_array"
                      :key="index"
                      cols="6"
                    >
                      <div class="d-flex">
                        <b-form-checkbox
                          class="custom-control-success"
                          name="check-button"
                          switch
                          inline
                          style="margin-top: 0.5rem; padding: 0rem 0rem 0.2rem 0rem;"
                          disabled
                        >
                          <span class="margin-top: 1rem; padding: 0rem 0rem 0.2rem 0rem;">
                            {{ time.show }}
                          </span>
                        </b-form-checkbox>
                      </div>
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-col>
            </b-row>

          </b-col>
        </b-row>

      </div>
    </b-card>

    <!-- Product Category Select -->
    <product-category-select
      v-model="showProductCategorySelectModal"
      :title="`${$t('Select')} ${$t('FormProduct.ProductCategory')}`"
      :product-category-data="productCategoryData"
      @update-product-category-select="updateProductCategorySelect"
      @discard-product-category-select="discardProductCategorySelect"
    />

    <!-- Department Select -->
    <department-select
      v-model="showDepartmentSelectModal"
      :title="`${$t('Select')} ${$t('FormProduct.Department')}`"
      :department-data="departmentData"
      @update-department-select="updateDepartmentSelect"
      @discard-department-select="discardDepartmentSelect"
    />

    <!-- Approver User Select -->
    <role-user-select
      v-model="showApproverUserSelectModal"
      :title="`${$t('Select')} ${$t('FormProduct.Approver')}`"
      :role-user-data="approverUserData"
      :role-user-filter="approverUserFilter"
      @update-role-user-select="updateApproverUserSelect"
      @discard-role-user-select="discardApproverUserSelect"
    />
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormCheckbox,
  BFormTimepicker,
  BFormRadio,
  BBadge,
} from 'bootstrap-vue'
import {
  ValidationProvider,
} from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import ProductCategorySelect from '@/views/master/product_category/ProductCategorySelect.vue'
import DepartmentSelect from '@/views/master/department/DepartmentSelect.vue'
import { quillEditor } from 'vue-quill-editor'
import RoleUserSelect from '@/views/booking/role/RoleUserSelect.vue'
import tableProduct from './tableProduct'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormCheckbox,
    BFormTimepicker,
    BFormRadio,
    BBadge,

    ValidationProvider,
    quillEditor,

    ProductCategorySelect,
    DepartmentSelect,
    RoleUserSelect,
  },
  props: {
    typeProductForm: {
      type: String,
      default: 'Add',
    },
    informationData: {
      type: Object,
      default: () => {},
    },
    busy: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showProductCategorySelectModal: false,
      productCategoryData: {},
      showDepartmentSelectModal: false,
      departmentData: {},
      showApproverUserSelectModal: false,
      approverUserData: {},
      approverUserFilter: {},

      descriptionOption: {
        modules: {
          toolbar: '#quill-toolbar-description',
        },
        placeholder: this.$i18n.t('FormProduct.Description'),
      },
      descriptionViewOption: {
        modules: {
          toolbar: false,
        },
      },
      breakTimeArray: [],
    }
  },
  watch: {
    // eslint-disable-next-line func-names
    'informationData.open_time': function (value) {
      if (value !== '') {
        if (this.informationData.close_time !== '') {
          if (value <= this.informationData.close_time) {
            this.setBreakTime()
          }
        }
      }
    },
    // eslint-disable-next-line func-names
    'informationData.close_time': function (value) {
      if (value !== '') {
        if (this.informationData.open_time !== '') {
          if (this.informationData.open_time <= value) {
            this.setBreakTime()
          }
        }
      }
    },
  },
  methods: {
    updateProductCategorySelect(data) {
      this.$nextTick(() => {
        this.informationData.category.value = data.select.id
        this.informationData.category.name = data.select.name
        this.productCategoryData.id = this.informationData.category.value
        this.productCategoryData.name = this.informationData.category.name

        this.informationData.category_id = data.select.id
        this.informationData.category_name = data.select.name
        this.informationData.type_code = data.select.type_code
        this.informationData.product_type = data.select.product_type
      })
    },
    discardProductCategorySelect() {
    },
    selectProductCategory() {
      if (this.informationData.category.value === '') {
        this.productCategoryData = {}
      } else {
        this.productCategoryData = {}
        this.productCategoryData.id = this.informationData.category.value
        this.productCategoryData.name = this.informationData.category.name
      }
      this.showProductCategorySelectModal = true
    },
    updateDepartmentSelect(data) {
      this.$nextTick(() => {
        this.informationData.department.value = data.select.id
        this.informationData.department.name = data.select.name
        this.departmentData.id = this.informationData.department.value
        this.departmentData.name = this.informationData.department.name

        this.informationData.department_id = data.select.id
        this.informationData.department_name = data.select.name
      })
    },
    discardDepartmentSelect() {
    },
    selectDepartment() {
      if (this.informationData.department.value === '') {
        this.departmentData = {}
      } else {
        this.departmentData = {}
        this.departmentData.id = this.informationData.department.value
        this.departmentData.name = this.informationData.department.name
      }
      this.showDepartmentSelectModal = true
    },
    updateApproverUserSelect(data) {
      this.$nextTick(() => {
        this.informationData.approver_user.value = data.select.user_id
        this.informationData.approver_user.name = data.select.user_name
        this.informationData.approver_user.email = data.select.user_email
        this.approverUserData.id = this.informationData.approver_user.value
        this.approverUserData.name = this.informationData.approver_user.name

        this.informationData.approver_user_id = data.select.user_id
        this.informationData.approver_user_name = data.select.user_name
        this.informationData.approver_user_email = data.select.user_email
      })
    },
    discardApproverUserSelect() {
    },
    selectApproverUser() {
      if (this.informationData.approver_user.value === '') {
        this.approverUserData = {}
        this.approverUserData.role_code = 'approver'
      } else {
        this.approverUserData = {}
        this.approverUserData.id = this.informationData.approver_user.value
        this.approverUserData.name = this.informationData.approver_user.name
        this.approverUserData.role_code = 'approver'
      }
      this.showApproverUserSelectModal = true
    },
    setBreakTime() {
      if (this.informationData.open_time !== '' && this.informationData.open_time !== '00:00'
      && this.informationData.close_time !== '' && this.informationData.close_time !== '00:00') {
        const openArray = this.informationData.open_time.split(':')
        const openHour = (openArray[0]) ? parseInt(openArray[0], 10) : null
        const openMin = (openArray[1]) ? parseInt(openArray[1], 10) : null
        const closeArray = this.informationData.close_time.split(':')
        const closeHour = (closeArray[0]) ? parseInt(closeArray[0], 10) : null
        const closeMin = (closeArray[1]) ? parseInt(closeArray[1], 10) : null
        if (openHour !== null && openMin !== null && closeHour !== null && closeMin !== null) {
          const start = new Date()
          start.setHours(openHour, openMin, 0)
          const end = new Date()
          end.setHours(closeHour, closeMin, 0)

          if (start <= end) {
            this.informationData.break_time_array = []
            while (start < end) {
              const first = start.toLocaleString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' })
              start.setMinutes(start.getMinutes() + 30)
              const last = start.toLocaleString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' })
              let valueBreakTime = false
              if (this.informationData.break_time !== null && this.informationData.break_time !== undefined && this.informationData.break_time !== '') {
                if (this.informationData.break_time.length > 0) {
                  if ((this.informationData.break_time.find(e => e === first)) !== undefined) {
                    valueBreakTime = true
                  }
                }
              }
              const time = {
                time: first,
                value: valueBreakTime,
                show: `${first} - ${last}`,
              }
              this.informationData.break_time_array.push(time)
            }
          }
        }
      }
    },
    IsNumber(event) {
      if (!/\d/.test(event.key)) return event.preventDefault()
      return null
    },
  },
  setup() {
    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation()

    const {
      textFirstUpper,
      isStatusVariant,
      isStatusToText,
      typeToText,
    } = tableProduct()

    return {
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,

      textFirstUpper,
      isStatusVariant,
      isStatusToText,
      typeToText,
    }
  },

}
</script>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/_variables';
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.modal{
  .line-height-info{
    padding-bottom: 0.6rem;
    &.end{
      padding-bottom: 0;
    }
  }
  .modal-header,
  .modal-header[class*='bg-'] {
    background-color: #fff !important;
  }
  .modal-body {
    background-color: $body-bg !important;
  }
}
.form-info-box{
  display: flex;
  padding: 0.6rem 1rem;
  background-color: #f8f8f8;
  background-clip: padding-box;
  border: 1px solid #f0f0f0;
  border-radius: 0.357rem;
  min-height: 38px;
}

.dark-layout {
  .modal{
    .line-height-info{
      padding-bottom: 0.6rem;
      &.end{
        padding-bottom: 0;
      }
    }
    .modal-header,
    .modal-header[class*='bg-'] {
      background-color: $theme-dark-body-bg !important;
    }
    .modal-body {
      background-color: $theme-dark-table-active-bg !important;
    }
  }
  .form-info-box{
    display: flex;
    padding: 0.6rem 1rem;
    background-color: $theme-dark-input-bg;
    background-clip: padding-box;
    border: 1px solid $theme-dark-border-color;
    border-radius: 0.357rem;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss" scoped>
form ::v-deep {
  // Quill Editor Style
  .quill-editor {
    .ql-editor {
     min-height: 200px;
    }
    .ql-container.ql-snow {
      border-bottom: 0 !important;
    }
  }
  .ql-toolbar {
    border: 1px solid #000000;
    border-radius: 0;
    border-top: 0px solid #000000;
    border-bottom: 1px solid #000000;
    .ql-picker.ql-expanded .ql-picker-options {
      bottom: 100%;
      top: auto;
    }
  }
}
</style>
