import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getDefaultDepartment(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/master/get_default_department', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getListDepartment(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/master/get_list_department', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getDepartment(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/master/get_department', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addDepartment(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/master/add_department', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editDepartment(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/master/edit_department', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteDepartment(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/master/delete_department', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getDepartmentOptions(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/master/department_options', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getListDepartmentSelect(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/master/get_list_department_select', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
